import React, { useState, useEffect } from "react";
import ActiveMonth from "./ActiveMonth";
import "./MonthWrapper.css";
import clock from "../../assets/clock.svg";

const MonthWrapper = ({ goPrev, goNext, goToday, year, month, todayStr }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const deadline = "March, 10, 2023";

  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    //   return () => clearInterval(interval);
  }, []);

  return (
    <div className="monthWrapper">
      <div className="header">
        <span className="btn arrow" onClick={goPrev}>
          &lt;
        </span>
        <span className="current" onClick={goToday}>
          {year} . {month}
        </span>
        <span className="btn arrow" onClick={goNext}>
          &gt;
        </span>
      </div>
      <ActiveMonth year={year} month={month} todayStr={todayStr} />
      <div>
        <img src={clock} />
        <div className="timeLeft">
          <span className="subTimeLeft"> {days} </span>:
          <span className="subTimeLeft"> {hours} </span>:
          <span className="subTimeLeft"> {minutes} </span>:
          <span className="subTimeLeft"> {seconds} </span>
          <br />
          till your exams
        </div>
      </div>
    </div>
  );
};

export default MonthWrapper;
