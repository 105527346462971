const getMonthDetail = ({ year, month }) => {
  const lastDate = new Date(year, month, 0).getDate();
  return Array(lastDate)
    .fill()
    .map((_, key) => key + 1);
};

const getDateDay = (d) => {
  return d.getDay();
};

const getTodayData = () => {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  return { year, month, date };
};

const getPrevYearMonth = ({ year, month }) => {
  const data = { year, month };
  if (month === 1) {
    data.year -= 1;
    data.month = 12;
  } else data.month -= 1;
  return data;
};

const getNextYearMonth = ({ year, month }) => {
  const data = { year, month };
  if (month === 12) {
    data.year += 1;
    data.month = 1;
  } else data.month += 1;
  return data;
};

const week = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

export {
  getMonthDetail,
  getDateDay,
  getTodayData,
  getPrevYearMonth,
  getNextYearMonth,
  week,
};
