import React, { useEffect, useState } from "react";
import HeaderSection from "../../components/HeadSection/HeadSection";
import Results from "../../components/Results/Results";
import AboutUs from "../../components/AboutUs/AboutUs";
import WhyUs from "../../components/whyUs/whyUs";
import Slider from "../../components/Slider/Slider";
import Slider2 from "../../components/Slider/Slider2";
import Footer from "../../components/Footer/Footer";
import Tutorials from "../../components/Tutorials/Tutorials";
import OurGallery from "../../components/OurGallery/OurGallery";
import FloatHeadline from "../../components/FloatHeadline/FloatHeadline";
import pop from "../../assets/pop.svg";
import { Image } from "react-bootstrap";
import "./LandingPage.css";
// import Modal from "../../components/Modal/Modal";

const LandingPage = () => {
  // const [load, setLoad] = useState(true);
  // const [lay, setLay] = useState(true);
  // useEffect(() => {
  //   setLoad(true);
  // }, []);
  return (
    <>
      {/* {load && lay && (
        <div>
          <div className="overlay"></div>
          <div className="pop-up">
            <img src={pop}></img>
          </div>
        </div>
      )} */}
      <HeaderSection />
      <FloatHeadline />
      <AboutUs />
      <WhyUs />
      {/* <Results /> */}
      <Slider2 />
      {/* <Slider /> */}
      <OurGallery />
      <Tutorials />
      <Footer />
    </>
  );
};

export default LandingPage;
