import React from "react";
import Carousel from "react-bootstrap/Carousel";
import kidFace from "../../assets/kid-face.svg";
import "./Slider.css";

const Slider2 = () => {
  return (
    <div className="bg-slider">
      <h1 className=" font-size-1 " >
        What<span className="yellow-col"> SCHOOLS </span> has to say about us
      </h1>
      <Carousel className="carousel" style={{ width: "100%", left: "0%" }}>
        {/* <Carousel.Item>
          <Carousel.Caption>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ut
              vulputate felis. Vestibulum ante ipsum primis in faucibus orci
              luctus et ultrices posuere cubilia curae
            </p>
            <h3 className="yellow-col">Bal Bharti Public School</h3>
          </Carousel.Caption>
          <img className="d-block md:w-60 w-24" src={kidFace} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <p className=" font-size-2 " > 
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ut
              vulputate felis. Vestibulum ante ipsum primis in faucibus orci
              luctus et ultrices posuere cubilia curae
            </p>
            <h3 className="yellow-col">Bal Bharti Public School</h3>
          </Carousel.Caption>
          <img className="d-block md:w-60 w-16 " src={kidFace} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ut
              vulputate felis. Vestibulum ante ipsum primis in faucibus orci
              luctus et ultrices posuere cubilia curae
            </p>
            <h3 className="yellow-col">Bal Bharti Public School</h3>
          </Carousel.Caption>
          <img className="d-block w-60" src={kidFace} alt="Third slide" />
        </Carousel.Item> */}
        <h2 id="comingSoon" className="yellow-col">
          COMING SOON
        </h2>
      </Carousel>
    </div>
    // <div className="bg-slider1">
    //   <h1>
    //     What<span className="yellow-col"> SCHOOLS </span> has to say about us
    //   </h1>
    //   <Carousel className="carousel" style={{ width: "100%" }}>
    //     <h2 className="yellow-col">COMING SOON</h2>
    //   </Carousel>
    // </div>
  );
};

export default Slider2;
