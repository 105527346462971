import React from "react";
import "./FloatingHeadline.css";
import img1 from "../../assets/image11.svg";
import img2 from "../../assets/image14.svg";
import img3 from "../../assets/image15.svg";
import img4 from "../../assets/image16.svg";
import img5 from "../../assets/KENDRIYA.svg";

const FloatHeadline = () => {
  return (
    <div>
      <div class="ticker-wrap">
        <div class="ticker">
          <div class="ticker__item">
            {" "}
            <img src={img1} alt="" />
          </div>
          <div class="ticker__item">
            <img src={img2} alt="" />
          </div>
          <div class="ticker__item">
            {" "}
            <img src={img3} alt="" />
          </div>
          <div class="ticker__item">
            <img src={img4} alt="" />
          </div>
          <div class="ticker__item">
            <img src={img5} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloatHeadline;
