import React, { useState, useEffect } from "react";
import Dropdown from "./Dropdown";
import Tutorial from "../../Portals/studentPortal/component/Tutorial";
import Mock from "./Mock";
import "./LearnHeader.css";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import emptyclass from "../../assets/emptyClass.gif";

const LearnHeader = () => {
  const [search, setSearch] = useState("");
  const [Class, setClass] = useState("");

  const [tut, setTut] = useState(false);
  const [mock, setMock] = useState(false);
  const [onLoad, setOnLoad] = useState(true);

  const tutorials = [
    {
      id: "1",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      class: "class1",
    },
    {
      id: "2",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
      class: "class2",
    },
    {
      id: "3",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
      class: "class3",
    },
    {
      id: "4",
      name: "udit world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
      class: "class1",
    },
    {
      id: "5",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
      class: "class1",
    },
    {
      id: "6",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
      class: "class2",
    },
  ];
  const mocks = [
    {
      id: "1",
      name: "Mock Test - Class 1",
      author: "John Doe",
      // videoUrl:'',
      thumbnail: "https://development.imo-india.org/mock-test/MOF_1.pdf",
      class: "class1",
    },
    {
      id: "2",
      name: "Mock Test - Class 2",
      author: "John Doe",
      // videoUrl:'',
      thumbnail: "https://development.imo-india.org/mock-test/MOF_2.pdf",
      class: "class2",
    },
    {
      id: "2",
      name: "Mock Test - Class 3",
      author: "John Doe",
      // videoUrl:'',
      thumbnail: "https://development.imo-india.org/mock-test/MOF_3.pdf",
      class: "class2",
    },
    {
      id: "2",
      name: "Mock Test - Class 4",
      author: "John Doe",
      // videoUrl:'',
      thumbnail: "https://development.imo-india.org/mock-test/MOF_4.pdf",
      class: "class2",
    },
    {
      id: "2",
      name: "Mock Test - Class 5",
      author: "John Doe",
      // videoUrl:'',
      thumbnail: "https://development.imo-india.org/mock-test/MOF_5.pdf",
      class: "class2",
    },
    {
      id: "2",
      name: "Mock Test - Class 6",
      author: "John Doe",
      // videoUrl:'',
      thumbnail: "https://development.imo-india.org/mock-test/MOF_6.pdf",
      class: "class2",
    },
    {
      id: "2",
      name: "Mock Test - Class 7",
      author: "John Doe",
      // videoUrl:'',
      thumbnail: "https://development.imo-india.org/mock-test/MOF_7.pdf",
      class: "class2",
    },
    {
      id: "2",
      name: "Mock Test - Class 8",
      author: "John Doe",
      // videoUrl:'',
      thumbnail: "https://development.imo-india.org/mock-test/MOF_8.pdf",
      class: "class2",
    },
    {
      id: "2",
      name: "Mock Test - Class 9",
      author: "John Doe",
      // videoUrl:'',
      thumbnail: "https://development.imo-india.org/mock-test/MOF_9.pdf",
      class: "class2",
    },
    {
      id: "2",
      name: "Mock Test - Class 10",
      author: "John Doe",
      // videoUrl:'',
      thumbnail: "https://development.imo-india.org/mock-test/MOF_10.pdf",
      class: "class2",
    },
    {
      id: "2",
      name: "Mock Test - Class 11",
      author: "John Doe",
      // videoUrl:'',
      thumbnail: "https://development.imo-india.org/mock-test/MOF_11.pdf",
      class: "class2",
    },
    {
      id: "2",
      name: "Mock Test - Class 12",
      author: "John Doe",
      // videoUrl:'',
      thumbnail: "https://development.imo-india.org/mock-test/MOF_12.pdf",
      class: "class2",
    },
  ];
  const getTutorials = () => {
    setTut(true);
    setMock(false);
    setOnLoad(false);
  };
  const getMockPapers = () => {
    setMock(true);
    setTut(false);
    setOnLoad(false);
  };
  const searchHandler = (e) => {
    setSearch(e.target.value);
  };
  return (
    <div>
      <br />
      <div className="mainLearnHeader">
        <div className="learnHeader">
          <div className="tut">
            <button onClick={getTutorials}>Tutorials</button>
          </div>
          <div className="mtp">
            <button onClick={getMockPapers}>Mock Test Papers</button>
          </div>
          <span className="learnHeader__subLine"></span>
          <Dropdown setClass={setClass} />
          <div className="subHeader__search">
            <input className="sh" type="text" onChange={searchHandler} placeholder="Search" />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      {(tut && (
        <div className="learnHeaderTut">
          <br />
          <h1>Tutorials</h1>
          <p>Coming soon!</p>
          {/* <Row>
            {tutorials
              .filter((filteredTutorial) =>
                filteredTutorial.name
                  .toLowerCase()
                  .includes(search.toLowerCase())
              )
              .filter((filterClass) =>
                filterClass.class.toLowerCase().includes(Class.toLowerCase())
              )
              .map((tutorial) => {
                return (
                  <Col key={tutorial.id} sm={12} md={6} lg={4} xl={4}>
                    <Tutorial tutorial={tutorial} />
                  </Col>
                );
              })}
          </Row> */}
        </div>
      )) ||
        (mock && (
          <div className="learnHeaderTut">
            <br />
            <h1>Mock Test Papers</h1>
            <Row>
              {mocks
                .filter((filteredMock) =>
                  filteredMock.name.toLowerCase().includes(search.toLowerCase())
                )
                .filter((filterClass) =>
                  filterClass.class.toLowerCase().includes(Class.toLowerCase())
                )
                .map((mock) => {
                  return (
                    <Col key={mock.id} sm={12} md={6} lg={4} xl={4}>
                      <Mock mock={mock} />
                    </Col>
                  );
                })}
            </Row>
          </div>
        ))}
      {onLoad && (
        <div style={{ textAlign: "center", padding:"15px" }}>
          <br />
          <br />
          <h3 style={{ color: "red", textTransform: "uppercase" }}>
            Can’t proceed further.
          </h3>
          <h4> Select class before proceeding ahead </h4>
          <img src={emptyclass} alt="" />
        </div>
      )}
    </div>
  );
};

export default LearnHeader;
