import React, { useEffect } from "react";
import classes from "./Header.module.css";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

// import searchIcon from "../../assets/search-icon.svg";
import emailUnread from "../../assets/mark_email_unread.svg";
import profile_pic from "../../assets/profile_pic.svg";
const Header = () => {
  
  const navigate = useNavigate();

  const submitlogout = (e) => {
    console.log("button click");
    e.preventDefault();

    if (e.target.value === 'saab'){
      localStorage.removeItem('enrollmentNo');
      navigate("/");
    }
  };

  return (
    <div className={classes.mainHeader}>
      <form>
        <input type="search" placeholder="What are you looking for ?" />
        <button type="submit">Search</button>
      </form>
      <div className={classes.subHeader}>
        <div >
        <img className="imageshead" src={emailUnread} alt="" />
        <img src={profile_pic} alt="" />
        <label for="cars"></label>
        </div>
        
        <div className="middle">
        <select onChange={submitlogout} name="cars" id="cars">
          <option value="volvo"></option>
          <option value="volvo">Settings</option>
          <option  value="saab">Log Out</option>
        </select>
        </div>
      </div>
    </div>
  );
};

export default Header;
