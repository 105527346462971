import React from "react";
import ourGallery from "../../assets/ourGallery.svg";
import "./ourGallery.css";
import { Button } from "react-bootstrap";

const OurGallery = () => {
  return (
    <div>
      <div className="about-us tut">
        {/* <span
          style={{
            position: "relative",
            left: "120px",
            top: "30px",
            width: "6px",
            height: "26px",
            backgroundColor: "red",
            display: "block",
          }}
        ></span>{" "}
        <h3>Our Gallery</h3> */}
        <div className="gallery_class">
          <div> 
            <h1 className="" >Olympiad 2022 in CENTERS</h1>
            <a href="/fame">
              <Button className="buttonview" variant="danger">View more -></Button>
            </a>
          </div>
          <div>
            <img className="image-gallery" src={ourGallery} alt="gallery" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurGallery;
