import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./tutorial.css";

const Tutorial = ({ tutorial }) => {
  return (
    <div className="tutorialCard">
      <Card className="my-3 p-3 rounded">
        <Link>
          <Card.Img src={tutorial.thumbnail} variant="top"></Card.Img>
        </Link>

        <Card.Body>
          <Card.Title as="div">
            <strong>{tutorial.name}</strong>
            <p style={{ color: "#888585" }}>{tutorial.author}</p>
          </Card.Title>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Tutorial;
