import React, { useState } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import classes from "./ClassList.module.css";
import MaterialTable from "material-table";
import axios from "axios";
import { ThemeProvider, createTheme } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import * as XLSX from 'xlsx';

import { Col, Row } from "reactstrap";

const StudentData = () => {
  const defaultMaterialTheme = createTheme();
  const [totalData, setTotalData] = useState(1);

  const [freshup, setFreshup] = useState(1);
  const [emptyData] = [];
  // useEffect(() => {
  //   if (hasFetchedData == false) {
  //     axios
  //       .get(
  //         `https://api.imo-india.org/school/students/get-all?schoolId=${localStorage.getItem("school-id")}&start=0&length=10`,
  //         {
  //           headers: {
  //             Authorization: "Bearer " + localStorage.getItem("token"),
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         if (res.status == 200) {
  //           setTabData(res.data.students);
  //           console.log(res);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, [hasFetchedData]);
  const tableColumns1 = [
    { title: "Roll No.", field: "rollNo" },
    { title: "Enroll. No.", field: "enrollmentNo" },
    {
      title: "Name of Student",
      field: "name",
    },
    { title: "Father/Guardian Name", field: "fathersName" },
    {
      title: "Mail",
      field: "email",
    },
    {
      title: "Mobile Number",
      field: "phoneNumber",
    },
  ];

  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        <div className={classes.mainClassList}>
          {/* <h2>List</h2> */}
          <Row className="mt-4">
            {/* <div>
              <Label
                htmlFor="formFile"
                className="form-label"
                style={{
                  background: "#1d3f2e",
                  padding: "7px 13px",
                  color: "white",
                  borderRadius: "7px",
                  marginLeft: "2vw",
                }}
              >
                Import
              </Label>
              <Input
                onChange={handleFile}
                className="form-control"
                type="file"
                id="formFile"
                style={{ visibility: "hidden" }}
              />
            </div> */}
            <Col lg={12} key={freshup}>
              {totalData == 1 ? (
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                    title="Students List"
                    data={(query) =>
                      new Promise((resolve, reject) => {
                        axios
                          .get(
                            `https://api.imo-india.org/school/students/get-all?schoolId=${localStorage.getItem(
                              "school-id"
                            )}&start=${query.page + 1}&length=${
                              query.pageSize
                            }`,
                            {
                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("token"),
                              },
                            }
                          )
                          .then((res) => {
                            if (res.status == 200) {
                              resolve({
                                data: res.data.students, // your data array
                                page: query.page, // current page number
                                totalCount: res.data.recordsFiltered, // total row number
                              });
                              // console.log(res);
                            }
                          })
                          .catch((err) => {
                            console.log(err);
                            if (err.response.status == 404) {
                              if (query.page == 0) {
                                setTotalData(0);
                                setFreshup(Math.random());
                              }
                            }
                            resolve();
                            // window.location.reload(false);
                          });
                        // }
                      })
                    }
                    columns={tableColumns1}
                    actions={[
                      {
                        icon: "refresh",
                        tooltip: "Refresh Data",
                        isFreeAction: true,
                      },
                    ]}
                    options={{
                      exportButton: true,
                      // exportAllData: false,
                      exportFileName: "Documents",
                    }}
                  />
                </ThemeProvider>
              ) : (
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                    title="Students List"
                    data={emptyData}
                    columns={tableColumns1}
                    actions={[
                      {
                        icon: "refresh",
                        tooltip: "Refresh Data",
                        isFreeAction: true,
                      },
                    ]}
                    options={{
                      exportButton: true,
                      // exportAllData: false,
                      exportFileName: "Documents",
                    }}
                  />
                </ThemeProvider>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default StudentData;
