import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

const Mock = ({ mock }) => {
  return (
    <div className="tutorialCard">
      <Card className="my-3 p-3 rounded">
        <Link to={mock.thumbnail} target="_blank">
          <Card.Img
            src="https://productivityandmore.com/wp-content/uploads/2022/09/PDF-Illustration-Notion.png"
            variant="top"
          ></Card.Img>
        </Link>

        <Card.Body>
          <Card.Title as="div">
            <strong>{mock.name}</strong>
            <p style={{ color: "#888585" }}>Download now</p>
          </Card.Title>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Mock;
