import React from "react";
import {
  getMonthDetail,
  getPrevYearMonth,
  getDateDay,
  getNextYearMonth,
  week,
} from "./utils.js";
import "./ActiveMonth.css"
const ActiveMonth = ({ year, month, todayStr }) => {
  const currMonth = getMonthDetail({ year, month });
  const prevMonth = getMonthDetail(getPrevYearMonth({ year, month }));
  const nextMonth = getMonthDetail(getNextYearMonth({ year, month }));

  const currMonthLastDate = currMonth[currMonth.length - 1];
  const startDay = getDateDay(new Date(year, month - 1, 1));
  const endDay = getDateDay(new Date(year, month - 1, currMonthLastDate));

  const prevMonthAddOn = prevMonth.slice(prevMonth.length - startDay);
  const nextMonthAddOn = nextMonth.slice(0, 6 - endDay);
  const visibleMonth = prevMonthAddOn
    .map((date) => ({ date, disabled: true }))
    .concat(currMonth.map((date) => ({ date, disabled: false })))
    .concat(nextMonthAddOn.map((date) => ({ date, disabled: true })));

  return (
    <div className="dates-wrapper" key={`${year}${month}`}>
      {week.map((el) => (
        <div className="week">{el}</div>
      ))}
      {visibleMonth.map((el) => (
        <div
          className={[
            "date",
            el.disabled ? " disabled" : "",
            todayStr === `${year}${month}${el.date}` ? " today" : "",
          ].join(" ")}
        >
          {el.date}
        </div>
      ))}
    </div>
  );
};

export default ActiveMonth;
