import React, { useState, useMemo, useEffect } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import greetImg from "../../assets/greetImg.svg";
import stateTrophy from "../../assets/stateTrophy.svg";
import internationalTrophy from "../../assets/internationalTrophy.svg";
import prompt from "../../assets/singing-contract.svg";
import classes from "./Sheets.module.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Input,
  Label,
  Button,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const SchoolHome = () => {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isSelected1, setIsSelected1] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabled1, setIsDisabled1] = useState(false);

  useEffect(() => {
    axios
      .get(
        `https://api.imo-india.org/admin/exams/get-status?schoolId=${localStorage.getItem(
          "school-id"
        )}`
      )
      .then((res) => {
        if (res.data.status == "Answer Sent") {
          setIsDisabled(true);
          setIsDisabled1(true);
          setIsSelected(true);
          setIsSelected1(true);
        } else if (res.data.status == "Question Sent") {
          setIsDisabled(false);
          setIsDisabled1(true);
          setIsSelected(false);
          setIsSelected1(false);
        } else if (res.data.status == "Question Received") {
          setIsDisabled(true);
          setIsDisabled1(false);
          setIsSelected(true);
          setIsSelected1(false);
        }
        console.log(res);
      })
      .catch((error) => {
        setIsDisabled(true);
        setIsDisabled1(true);
        console.log(error);
      });
  }, [isSelected, isSelected1]);

  const toggle = () => setModal(!modal);
  const toggle1 = () => setModal1(!modal1);
  const handlesubmit = (event) => {
    event.preventDefault();
    let data = {};
    axios
      .put(
        "https://api.imo-india.org/school/exams/receive-question-paper",
        data,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setIsSelected(true);
        // toast.success(res.response.data.message);
        console.log(res);
        toggle();
      })
      .catch((error) => {
        toast.error(error.data.message);
        console.log(error);
      });
  };
  const handlesubmit1 = (e) => {
    e.preventDefault();
    let data = {};
    axios
      .put("https://api.imo-india.org/school/exams/send-answer-sheet", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setIsSelected1(true);
        toast.success(res.data.message);
        // console.log(res);
        toggle1();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };
  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        <div className={classes.mainHome}>
          {/* <h2>Your Rank</h2> */}
          <div className={classes.rankings}>
            <div className={classes.formCheckSwitch}>
              <div className={classes.fromCheckLeft}>
                <Label className="form-check-label" for="isReceived">
                  Question Paper Received
                </Label>
                <p>
                  By ticking this, you are assuring IMO that the school has
                  received Question Paper.
                </p>
              </div>
              <div className={classes.fromCheckRight}>
                <Input
                  onClick={toggle}
                  disabled={isDisabled}
                  checked={isSelected}
                  className="custom-toggle custom-toggle-success"
                  type="checkbox"
                  id="isReceived"
                />
              </div>
            </div>
            <div className={classes.formCheckSwitch}>
              <div className={classes.fromCheckLeft}>
                <Label className="form-check-label" for="isReceived">
                  Answer Sheets Sent
                </Label>
                <p>
                  By ticking this, you are assuring IMO that the school has sent
                  Answer Sheets.
                </p>
              </div>
              <div className={classes.fromCheckRight}>
                <Input
                  onClick={toggle1}
                  disabled={isDisabled1}
                  checked={isSelected1}
                  className="custom-toggle custom-toggle-success"
                  type="checkbox"
                  id="isReceived"
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          isOpen={modal}
          toggle={toggle}
        >
          <ModalHeader toggle={toggle}>Send Confirmation</ModalHeader>
          <ModalBody>Do you wanna confirm?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handlesubmit}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          isOpen={modal1}
          toggle={toggle1}
        >
          <ModalHeader toggle={toggle1}>Send Confirmation</ModalHeader>
          <ModalBody>Do you wanna confirm?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handlesubmit1}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={toggle1}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default SchoolHome;
