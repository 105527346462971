import React, { useState, useEffect } from "react";
import { Form, Button, Container, Table } from "react-bootstrap";
// import classes from "./ResultForm.module.css";
import axios from "axios";

const ResultForm = () => {
  const [rollNo, setRollNo] = useState("");
  const [name, setName] = useState("");
  const [school, setSchool] = useState("");
  const [classes, setClasses] = useState("");
  const [marks, setMarks] = useState("");
  const [exist, setExist] = useState(false);
  const [selected, setSelected] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    console.log("submithandler");
    const { data } = await axios.get(
      `https://api.imo-india.org/student/${rollNo}`,
      config
    );

    setExist(true);
    setClasses(data[0].CLASS);
    setName(data[0].STUDENT_NAME);
    setMarks(data[0].MARKS);
    setSchool(data[0].SCHOOL_NAME);

    if (
      (data[0].CLASS === "10" ||
        data[0].CLASS === "8" ||
        data[0].CLASS === "11" ||
        data[0].CLASS === "12") &&
      data[0].MARKS >= 65
    ) {
      setSelected(true);
      return;
    } else if (data[0].CLASS === "9" && data[0].MARKS >= 60) {
      setSelected(true);
      return;
    } else if (data[0].MARKS >= 70) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  };

  return (
    <div className={classes.resultForm}>
      <Container>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Enter name" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Roll Number</Form.Label>
            <Form.Control
              value={rollNo}
              type="text"
              placeholder="Roll number"
              onChange={(e) => setRollNo(e.target.value)}
            />
          </Form.Group>
          <button onClick={submitHandler}>Submit</button>
          {/* <Button variant="success" type="submit"> */}
          {/* Submit */}
          {/* </Button> */}
        </Form>
        <br />
        {selected && <h1>Congratulations! You are Selected for Level 2</h1>}
        <br />
        {exist && (
          <Table striped bordered hover variant="success">
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Roll Number</th>
                <th>Class</th>
                <th>Marks</th>
                <th>School Name</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{name}</td>
                <td>{rollNo}</td>
                <td>{classes}</td>
                <td>{marks}</td>
                <td>{school}</td>
              </tr>
            </tbody>
          </Table>
        )}
      </Container>
    </div>
  );
};

export default ResultForm;
