import React, { useState, useMemo } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import classes from "./SingleSchoolData.module.css";
import { Link,  useParams, } from "react-router-dom";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

const AdmitCard = (props) => {
  const defaultMaterialTheme = createTheme();
  const type = useParams();
  const tableColumns1 = [
    {
      title: "Enroll No.",
      field: "enrollNo",
    },
    {
      title: "Name of Student",
      field: "studentName",
    },
    { title: "Father/Guardian Name", field: "fatherName" },
    {
      title: "Mail",
      field: "mail",
    },
    {
      title: "Mobile Number",
      field: "mobileNum",
    },
    {
      title: "Class",
      field: "class",
      // render: rowData=>{console.log(type)}
    },
  ];
  const [tableData, setTableData] = useState([
    {
      enrollNo: "-",
      studentName: "Tushar Singh",
      fatherName: "Manoj",
      mail: "xyz@gmail.com",
      mobileNum: "8927146723",
      class: "3",
    },
    {
      enrollNo: "-",
      studentName: "Apoorv",
      fatherName: "Gaurav",
      mail: "xyz@gmail.com",
      mobileNum: "9925146723",
      class: "5",
    },
  ]);

  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        <div className={classes.mainClassList}>
          {/* <h2>List</h2> */}
          <button>Generate Enrollment Number</button>
          <Row className="mt-4">
                <Col lg={12}>
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                    title="Admit Card"
                    data={tableData}
                    columns={tableColumns1}
                    actions={[
                      {
                        icon: "refresh",
                        tooltip: "Refresh Data",
                        isFreeAction: true,
                      },
                    ]}
                    options={{
                      exportButton: false,
                      exportAllData: false,
                      exportFileName: "Documents",
                      sorting: true,
                    }}
                  />
                  </ThemeProvider>
                </Col>
              </Row>
        </div>
      </div>
    </div>
  );
};

export default AdmitCard;
