import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

const Protected = (props) => {
  const {Component} = props;
  const token = localStorage.getItem("token");
  if(!token){
    return <Navigate to="/school/log-in" replace />;
  }
  return (
    <div><Component/></div>
  )
};

export default Protected;