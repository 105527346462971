import React from "react";
import Carousel from "react-bootstrap/Carousel";
import kidFace from "../../assets/kid-face.svg";
import "./Slider.css";

function UncontrolledExample() {
  return (
    <div className="bg-slider">
      <h1>
        Hear from our <span className="yellow-col"> STUDENT </span>
      </h1>
      <Carousel className="carousel" style={{ width: "100%", left: "0%" }}>
        <Carousel.Item>
          <img className="d-block w-60" src={kidFace} alt="First slide" />
          <Carousel.Caption>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ut
              vulputate felis. Vestibulum ante ipsum primis in faucibus orci
              luctus et ultrices posuere cubilia curae
            </p>
            <h3 className="yellow-col">Raghav Mishra</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-60" src={kidFace} alt="Second slide" />

          <Carousel.Caption>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ut
              vulputate felis. Vestibulum ante ipsum primis in faucibus orci
              luctus et ultrices posuere cubilia curae
            </p>
            <h3 className="yellow-col">Raghav Mishra</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-60" src={kidFace} alt="Third slide" />

          <Carousel.Caption>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ut
              vulputate felis. Vestibulum ante ipsum primis in faucibus orci
              luctus et ultrices posuere cubilia curae
            </p>
            <h3 className="yellow-col">Raghav Mishra</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default UncontrolledExample;
