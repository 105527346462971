import React from "react";
import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import { Image } from "react-bootstrap";
import img1 from "../../assets/fame-1.png";
import img2 from "../../assets/fame-2.png";
import img3 from "../../assets/fame-3.png";
import img4 from "../../assets/fame-4.png";
import fame1 from "../../assets/fame-1.svg";
import fame2 from "../../assets/fame-2.svg";
import fame3 from "../../assets/fame-3.svg";
import fame4 from "../../assets/fame-4.svg";
import fame5 from "../../assets/fame-5.svg";
import fame6 from "../../assets/fame-6.svg";
import "./Fane.css";

const Fame = () => {
  const data = [
    {
      id: 1,
      imgSrc: fame1,
    },
    {
      id: 2,
      imgSrc: fame2,
    },
    {
      id: 3,
      imgSrc: fame3,
    },
    {
      id: 4,
      imgSrc: fame4,
    },
    {
      id: 5,
      imgSrc: fame5,
    },
    {
      id: 6,
      imgSrc: fame6,
    },
  ];
  return (
    <div>
      <Header />
      <div className="gallery-fame">
        <h1>Gallery of Fame</h1>
        <div className="portfolio-page">
          {data.map((item) => {
            return (
              <div key={item.id} className="portfolio-page__item">
                <img
                  style={{ width: "100%" }}
                  // key={item.id}
                  src={item.imgSrc}
                  alt=""
                  // loading="lazy"
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="parLine"></div>
      <br />
      <br />
      <div className="fame-list">
        <div className="fame-1">
          <Image src={img1}></Image>
          <h4>Olympiad 2021</h4>
        </div>
        <div className="fame-1">
          <Image src={img2}></Image>
          <h4>Annual Awards 2021</h4>
        </div>
        <div className="fame-1">
          <Image src={img3}></Image>
          <h4>Hall of Fame 2021</h4>
        </div>
        <div className="fame-1">
          <Image src={img4}></Image>
          <h4>Team IMO</h4>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Fame;
