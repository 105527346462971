import React, { useState, useMemo } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import classes from "./SchoolData.module.css";
// import { Link,  useParams, } from "react-router-dom";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
import { Link } from "@material-ui/core";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

const AnswerTransport = () => {
  const defaultMaterialTheme = createTheme();

  const tableColumns1 = [
    {
      title: "Name of School",
      field: "schoolName",
    },
    { title: "Address", field: "address" },
    { title: "Date", field: "date" },
    {
      title: "Phone Number",
      field: "mobileNum",
    },
    {
      title: "Details", 
      field: "details",
      // lookup: { true: "Yes", false: "No" },
      // render:rowData=><Link href={`/admin/school-data/${rowData.schoolName}`} target="_blank">View</Link>
    },
  ];
  const [tableData, setTableData] = useState([
    {
      schoolName: "Ravindra Public School",
      address: "QD block (near tv tower) Pitampura",
      date: "02-12-2022",
      mobileNum: "8927146723",
      details: "Sent",
    },
    {
      schoolName: "Queen Mary's School",
      address: "Railway Colony, Tiz Hazari",
      date: "02-12-2022",
      mobileNum: "9947146723",
      details: "Send",
    },
  ]);
  const [sort, setSort] = useState(null);
  const sortList = [
    {
      id: 1,
      text: "Total Schools",  
      catt: "total-schools",
    },
    {
      id: 2,
      text: "Answer Paper Sent", 
      catt: "answer-paper-sent", 
    },
    { id: 3,
      text: "Remaining Schools", 
      catt: "remaining-schools", 
    }
    ];

  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        <div className={classes.mainClassList}>
          {/* <h2>List</h2> */}
          <div className={classes.filtersClassList}>
          {sortList.map(item=>(
              <div onClick={() => setSort(item.catt)} key={item.id} className={sort === item.catt ? "sortElementActive" : "sortElement"} >
                <h5>{item.text}</h5>
              </div>
            ))}
          </div>
          <Row>
                <Col lg={12}>
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                    title="Transportation Data"
                    data={tableData}
                    columns={tableColumns1}
                    actions={[
                      {
                        icon: "refresh",
                        tooltip: "Refresh Data",
                        isFreeAction: true,
                      },
                    ]}
                    options={{
                      exportButton: false,
                      exportAllData: false,
                      exportFileName: "Documents",
                      sorting: true,
                    }}
                  />
                  </ThemeProvider>
                </Col>
              </Row>
        </div>
      </div>
    </div>
  );
};

export default AnswerTransport;
