import React, { useState } from "react";
import classes from "./Signup.module.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import gif from "../../assets/signup-login.gif";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./login.css";

import { Col, Input, Container, Label, Row } from "reactstrap";

const Login = () => {
  const [enrollmentNo, setEnrollmentNo] = useState("");
  const [err, setErr] = useState(null);
  const navigate = useNavigate();

  const submitHandler = (event) => {
    console.log("button click");
    event.preventDefault();
    
    axios
      .post("https://api.imo-india.org/school/student/login", {
        enrollmentNo: enrollmentNo
      })
      .then(function(response) {
        localStorage.setItem('enrollmentNo', enrollmentNo);
        // localStorage.setItem("token", response.data.token);
        navigate("/student/home");
        console.log(response);
      })
      .catch(function(error) {
        alert("Error")
        console.log(error);
      });
      console.log("reached")
  };

  return (
    <React.Fragment>
      <div className="top">
        <div >
          <img className="top1" src={gif} alt="" />
        </div>
        <div className="mid">
          <div className="mid2">
            <h2>
              Hi, <br /> Welcome
            </h2>
            <p className="mid3">Kindly LogIn to your account</p>
            <br />
            <Row className="rows">
              <Col xxl={6} md={12} className="mt-3">
                <div className="inp">
                  <Label htmlFor="enrollmentNumber" className="mid4">
                    <h2 className="zmdi zmdi-email material-icon-name" />
                    Enrollment Number
                  </Label>
                  <Input
                    value={enrollmentNo}
                    onChange={(e) => {
                      setEnrollmentNo(e.target.value);
                    }}
                    type="text"
                    name="text"
                    id="enrollmentNumber"
                    className="form-control"
                    autoComplete="off"
                  />
                </div>
              </Col>
              <Col xxl={4} md={6} className="buttonlogin">
                <button className="btn btn-success" onClick={submitHandler}>
                  Log-In <h2 className="zmdi zmdi-mail-send" />
                </button>
              </Col>
              <Col xxl={6} md={12}>
                <p id={classes.errorMessage}>{err}</p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
