import React, { useState, useMemo } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import classes from "./ClassList.module.css";
import { Link,  useParams, } from "react-router-dom";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

const Details = () => {
  const defaultMaterialTheme = createTheme();

  const tableColumns1 = [
    { title: "Roll No.", field: "rno"},
    { title: "Enroll. No.", field: "enrollNum" },
    {
      title: "Name of Student",
      field: "studentName",
      // lookup: groupTypeOptionsDataLinks,
      // defaultFilter: ["65"],
      // lookup: { dataLinkTables: JSON.parse(JSON.stringify(dataLinkTables)) },
    },
    { title: "Father/Guardian Name", field: "fatherName" },
    {
      title: "Mail",
      field: "mail",
    },
    {
      title: "Mobile Number",
      field: "mobileNum",
      // lookup: { true: "True", false: "False" },
    },
  ];
  const [tableData, setTableData] = useState([
    {
      rno: "1",
      enrollNum: "2144677",
      studentName: "Tushar Singh",
      fatherName: "Manoj",
      mail: "xyz@gmail.com",
      mobileNum: "8927146723",
    },
    {
      rno: "2",
      enrollNum: "2144678",
      studentName: "Apoorv",
      fatherName: "Gaurav",
      mail: "xyz@gmail.com",
      mobileNum: "9925146723",
    },
  ]);

  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        <div className={classes.mainClassList}>
          <h2>List</h2>
          <Row className="mt-4">
                <Col lg={12}>
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                    title="Students List"
                    data={tableData}
                    columns={tableColumns1}
                    actions={[
                      {
                        icon: "refresh",
                        tooltip: "Refresh Data",
                        isFreeAction: true,
                      },
                    ]}
                    options={{
                      exportButton: false,
                      exportAllData: false,
                      exportFileName: "Documents",
                    }}
                  />
                  </ThemeProvider>
                </Col>
              </Row>
        </div>
      </div>
    </div>
  );
};

export default Details;
