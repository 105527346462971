import React from "react";
import SideBar from "./SideBar.js";

const ResultPage = () => {
  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div></div>
    </div>
  );
};

export default ResultPage;
