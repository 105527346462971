import React, { useState, useEffect } from "react";
import Home from "../../assets/home.svg";
import admitCard from "../../assets/admitCard.svg";
import myResults from "../../assets/myResults.svg";
import tutorials from "../../assets/tutorials.svg";
import certi from "../../assets/certi.svg";
import set from "../../assets/set.svg";
// import mof_logo from "../../assets/mof_logo.svg";
import classes from "./SideBar.module.css";
import { Link } from "react-router-dom";

const SideBar = () => {
  // class toggle
  const [toggle, setToggle] = useState(false);
  console.log(toggle);
  const toggleClassHandler = (e) => {
    setToggle(!toggle);
  };
  useEffect(() => {}, [toggle]);
  return (
    <div>
      <div className={classes.sidebar}>
        <div className={classes.mof}>
          {/* <Link to="/student/home">
            <img src={mof_logo} alt="" />
          </Link> */}
        </div>
        <div className={classes.sideBarTxt}>
          <ul>
            <Link to="/admin/school-data">
              <li>
                <img className={classes.sideBarIcon} src={Home} alt="" /> School
                Data
              </li>
            </Link>
            <Link to="/admin/finance-data">
              <li>
                <img className={classes.sideBarIcon} src={admitCard} alt="" />{" "}
                Finance Data Receipt
              </li>
            </Link>
            <Link to="/admin/transport-data">
              <li>
                <img className={classes.sideBarIcon} src={myResults} alt="" />{" "}
                Transport Data
              </li>
            </Link>
            <Link to="/admin/tutorials">
              <li>
                <img className={classes.sideBarIcon} src={tutorials} alt="" />{" "}
                Tutorials
              </li>
            </Link>
            <Link to="/admin/master-admit" onClick={toggleClassHandler}>
              <li>
                <img className={classes.sideBarIcon} src={certi} alt="" />{" "}
                Master AdmitCard
              </li>
            </Link>
            <Link to="/admin/master-certificate" onClick={toggleClassHandler}>
              <li>
                <img className={classes.sideBarIcon} src={certi} alt="" />{" "}
                Master Certificates
              </li>
            </Link>
            <Link to="/admin/settings">
              <li>
                <img className={classes.sideBarIcon} src={set} alt="" />{" "}
                Settings
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
