import React from "react";
import { Fragment } from "react";
import MOF_LOGO from "../../assets/mof_logo1.jpeg";
import { Link } from "react-router-dom";
import classes from "./Header.module.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Header = () => {

  return (
    <Fragment>
      <div className={classes.main} style={{backgroundColor: "white", position: "fixed", zIndex: 999, width: "99vw"}}>
        <div className={classes.mof_logo}>
          <Link to="/">
          <img src={MOF_LOGO} alt="" className="imagehead" style={{ maxWidth: '28rem', maxHeight: '15rem', width:'100%' }} />
          </Link>


          <div className="nav">  
          <li className="nav-item dropdown d-block d-md-none d-lg-none d-xl-none">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Explore
          </a>
          <ul class="dropdown-menu">
            <li><a className="dropdown-item"><Link to="/about">About</Link></a></li>
            <li><a className="dropdown-item"><Link to="/toppers">Toppers</Link></a></li>
            <li><hr className="dropdown-divider" /></li>
            <li><a className="dropdown-item"><Link to="/school/log-in"><button className={classes.regBtn}>School Registration</button></Link></a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item"><Link to="/student/login"><button className={classes.conBtn}>Student Login </button></Link></a></li>
          </ul>
        </li>
        </div>


        </div>

        <div className={classes.options}>
          <ul>
            <Link to="/">
              <li>Home</li>
            </Link>
            <Link to="/about">
              <li>About</li>
            </Link>
            <Link to="/toppers">
              <li>Toppers</li>
            </Link>
            <div>
              <div className={classes.dropdown}>
                <Link className={classes.dropbtn}>
                  <li>
                    Learn
                    <i
                      style={{ marginLeft: "4px", color: "black" }}
                      class="fa fa-caret-down"
                    ></i>
                  </li>
                </Link>
                <div className={classes.dropdown_content}>
                  <Link to="/learn">Tutorials</Link>
                  <Link to="/learn">Mock Test Paper</Link>
                  <Link to="/about">Syllabus</Link>
                </div>
              </div>
              {/* <Link to="/learn">
                <li className={classes.learn}>Learn</li>
              </Link> */}
            </div>     
            <Link to="/contact">
              <li>Contact Us</li>
            </Link>
          </ul>
        </div>
        <div className={classes.btnRegCon}>
          <Link to="/school/log-in">
            <button className={classes.regBtn}>School Registration</button>
          </Link>
          <Link to="/student/login">
            <button className={classes.conBtn}>
              Student Login
            </button>
          </Link>
        </div>
      </div>
    </Fragment>
  
  );
};

export default Header;
