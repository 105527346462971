import React, { useState, useMemo, useEffect } from "react";
import SideBar from "./SideBar.js";
import { Link } from "react-router-dom";
import classes from "./Tutorial.module.css";
import Header from "./Header.jsx";
import { Row, Col } from "react-bootstrap";
import Tutorial from "./component/Tutorial";
import tutorials from "../../assets/tutorials.svg";
import Home from "../../assets/home.svg";
import admitCard from "../../assets/admitCard.svg";
import myResults from "../../assets/myResults.svg";
import mof_logo from "../../assets/mof_logo.svg";
import certi from "../../assets/certi.svg";
import { useNavigate } from "react-router-dom";

const Tutorials = () => {
  const tutData = [
    {
      id: "1",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
    },
    {
      id: "2",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
    },
    {
      id: "3",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
    },
    {
      id: "4",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
    },
    {
      id: "5",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
    },
    {
      id: "6",
      name: "Hello world",
      author: "John Doe",
      // videoUrl:'',
      thumbnail:
        "https://www.practice-olympiad.com/images/what-is-olympiad.jpg",
    },
  ];
  
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  // console.log(toggle);
  const toggleClassHandler = (e) => {
    setToggle(!toggle);
  };
  useEffect(() => {}, [toggle]);

  const submitlogout = (e) => {
    console.log("button click");
    e.preventDefault();

    if (e.target.value === 'saab'){
      localStorage.removeItem('enrollmentNo');
      navigate("/");
    }
  };

  return (
    <div>

<div className="nav">
      <nav class="navbar navbar-dark bg-dark fixed-top d-block d-md-none d-lg-none d-xl-none">
  <div class="container-fluid">
    <a class="navbar-brand"><Link to="/student/home"><img src={mof_logo} alt="" style={{ maxHeight: '60px', }}/></Link></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">Welcome</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page"><Link to="/student/home"><img className={classes.sideBarIcon} src={Home} alt="" /> Home  </Link></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#"><Link to="/student/admitcard"><img className={classes.sideBarIcon} src={myResults} alt="" />{" "}Admit Card</Link></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#"><Link to="/student/certificate" onClick={toggleClassHandler}><img className={classes.sideBarIcon} src={certi} alt="" />{" "}Certificates</Link></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#"><Link to="/student/tutorials"><img className={classes.sideBarIcon} src={tutorials} alt="" />{" "}Tutorials</Link></a>
          </li>
          <li>
          <div className=" middle ">
        <select onChange={submitlogout} name="cars" id="cars">
          <option value="volvo">More</option>
          <option value="volvo">Settings</option>
          <option  value="saab">Log Out</option>
        </select>
        </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
</div>


      <div>
        <SideBar />
      </div>
      <div>
        <div>
          <Header />
        </div>
        <div className={classes.mainTut}>
          <Link style={{ textDecoration: "none" }} to="/">
            <h4>Tutorials</h4>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/">
            <h4>Mock Test Paper</h4>
          </Link>
        </div>
        <div className={classes.allTutorial}>
          <Row>
            {tutData.map((tutorial) => {
              return (
                <Col key={tutorial.id} sm={12} md={6} lg={4} xl={4}>
                  <Tutorial tutorial={tutorial} />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Tutorials;
