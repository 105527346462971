import React from "react";
import { Fragment } from "react";
import Bacha from "../../assets/bacha.svg";
import Header from "../Header/Header";
import BuyBooksHead from "../BuyBooksHead/BuyBooksHead";
import classes from "./HeadSection.module.css";
import { Link } from "react-router-dom";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
import Car2 from "../../assets/car2.png";

const HeadSection = () => {
  return (
    <>
      {/* <Carousel autoPlay infiniteLoop>
        <div className={classes.mainSectionHead}>
          <div className={classes.subSectionHead}>
            <div className={classes.subText}>
              <h1 style={{ fontSize: "3rem" }}>
                Pure <span className={classes.codeRed}>mathematics</span> is, in
                its way, the poetry of
                <span className={classes.codeRed}> logical ideas.</span>
                <br />
              </h1>
              <h4>~ Albert Einstein</h4>
            </div>
            <div className={classes.bachaImg}>
              <img src={Bacha} alt="" />
            </div>
          </div>
        </div>
        <div className={classes.mainSectionHead}>
          <div className={classes.subSectionHead}>
            <div className={classes.subText}>
              <h1 style={{ fontSize: "3rem" }}>
                Pure <span className={classes.codeRed}>mathematics</span> is, in
                its way, the poetry of
                <span className={classes.codeRed}> logical ideas.</span>
                <br />
              </h1>
              <h4>~ Albert Einstein</h4>
            </div>
            <div className={classes.bachaImg}>
              <img src={Car2} alt="" />
            </div>
          </div>
        </div>
      </Carousel> */}
      <div className={classes.mainSectionHead}>
        <Header />
        <div className={classes.subSectionHead}>
          <div className={classes.subText}>
            <h1 className="font" style={{ fontSize: "2.5rem" }}>
              Pure <span className={classes.codeRed}>mathematics</span> is, in
              its way, the poetry of
              <span className={classes.codeRed}> logical ideas.</span>
              <br />
            </h1>
            <h4>~ Albert Einstein</h4>
          </div>
          <div className={classes.bachaImg}>
            <img src={Bacha} alt="" />
          </div>
        </div>
      </div>
      {/* <BuyBooksHead /> */}
    </>
  );
};

export default HeadSection;
