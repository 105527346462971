import React, { useState, useRef } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import classes from "./ClassList.module.css";
import { useParams } from "react-router-dom";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Col, Input, Label, Row } from "reactstrap";

const ClassList = (props) => {
  const { type } = useParams();
  const defaultMaterialTheme = createTheme();
  // const [hasFetchedData, setHasFetchedData] = useState(false);
  // const [tabData, setTabData] = useState([]);
  // const [start, setStart] = useState(1);
  // const [length, setLength] = useState(5);
  // const [totalDataLength, setTotalDataLength] = useState(10);
  const tableRef = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [totalData, setTotalData] = useState(1);
  const [freshup, setFreshup] = useState(1);
  const [fathersName, setFathersName] = useState("");
  const tableColumns1 = [
    // { title: "Enroll. No.", field: "enrollNum" },
    {
      title: "Name of Student",
      field: "name",
      // lookup: groupTypeOptionsDataLinks,
      // defaultFilter: ["65"],
      // lookup: { dataLinkTables: JSON.parse(JSON.stringify(dataLinkTables)) },
    },
    { title: "Father/Guardian Name", field: "fathersName" },
    {
      title: "Mail",
      field: "email",
    },
    {
      title: "Mobile Number",
      field: "phoneNumber",
      // lookup: { true: "True", false: "False" },
    },
  ];
  const [tableData, setTableData] = useState([
    {
      // enrollNum: "2144677",
      studentName: name,
      fathersName: fathersName,
      mail: email,
      mobileNum: phoneNumber,
    },
    // {
    // enrollNum: "2144678",
    // studentName: "Apoorv",
    // fatherName: "Gaurav",
    // mail: "xyz@gmail.com",
    // mobileNum: "9925146723",
    // },
  ]);

  const onClickAdd = (obj) => {
    // console.log("add: ");
    // console.log(obj.name);
    // const aData = {
    //   name: obj.name, email: obj.email, profileImage: obj.profileImage, phoneNumber: obj.phoneNumber, fathersName: obj.fathersName
    // }
    axios
      .post(
        `https://api.imo-india.org/school/students/create?classId=${type}&schoolId=${localStorage.getItem(
          "school-id"
        )}`,
        obj,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.status == 201) {
          tableRef.current.onQueryChange();
          // setTabData(res.data.createdStudents);
          // console.log("result: ");
          console.log(res);
          setTotalData(1);
          toast.success(res.data.message);
          // setTabData([...tabData, obj]);
        }
      })
      .catch((err) => {
        // console.log("error--: ");
        // console.log(err);
        toast.error(err.response.data.error);
      });
  };
  const onClickUpdate = (id, obj) => {
    // console.log("Update: ");
    // console.log(id);
    // console.log(obj);
    axios
      .put(
        `https://api.imo-india.org/school/students/update-student/?id=${id}`,
        obj,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          tableRef.current.onQueryChange();
          // setTabData(res.data.createdStudents);
          // console.log("result: ");
          // console.log(res);
          toast.success(res.data.message);
          // setTabData([...tabData, obj]);
        }
      })
      .catch((err) => {
        // console.log("error--: ");
        // console.log(err);
        toast.error(err.response.data.message);
      });
  };
  const onClickDelete = (obj) => {
    // console.log("Delete: ");
    // console.log(obj);
    axios
      .delete(
        `https://api.imo-india.org/school/students/delete-student/?id=${obj.id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          tableRef.current.onQueryChange();
          // console.log("result: ");
          // console.log(res.data.message);
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        // console.log("error--: ");
        // console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };

  const [csv, setCsv] = useState("");
  const [csv1, setCsv1] = useState("");
  function handleFile(e) {
    e.preventDefault();
    let file = e.target.files[0];
    setState({ file: file });
    console.log(e.target.files);
    setCsv(e.target.files[0]);
    const csvFile = new FormData();
    csvFile.append("csvFile", file);

    axios
      .post(
        `https://api.imo-india.org/school/students/create-csv?classId=${type}&schoolId=${localStorage.getItem(
          "school-id"
        )}`,
        csvFile,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          tableRef.current.onQueryChange();
          // setTabData([...tabData, res.data.createdStudents]);
          // console.log("CSV FILE");
          // console.log(res);
          setTotalData(1);
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        // console.log("error--: ");
        console.log(err);
        toast.error(err.response.data.message);
      });
  }
  // function handleFile1(e) {
  //   e.preventDefault();
  //   let file = e.target.files[0];
  //   setState1({ file: file });
  //   console.log(e.target.files);
  //   setCsv1(e.target.files[0]);
  //   const csvFile = new FormData();
  //   csvFile.append("csvFile", file);

  //   axios
  //     .post(
  //       `https://api.imo-india.org/school/student/absentee-list?schoolId=${localStorage.getItem(
  //         "school-id"
  //       )}`,
  //       csvFile,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("token"),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (res.status == 200) {
  //         // tableRef.current.onQueryChange();
  //         // setTabData([...tabData, res.data.createdStudents]);
  //         // console.log("CSV FILE");
  //         // console.log(res);
  //         // setTotalData(1);
  //         toast.success(res.data.message);
  //       }
  //     })
  //     .catch((err) => {
  //       // console.log("error--: ");
  //       console.log(err);
  //       toast.error(err.response.data.message);
  //     });
  // }
  const [state, setState] = useState({
    file: null,
  });
  const [state1, setState1] = useState({
    file: null,
  });
  const [seed, setSeed] = useState(1);
  const [emptyData] = [];
  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        <div className={classes.mainClassList}>
          {/* <h2>List</h2> */}
          <Row className="mt-4">
            <Col xl={2} md={2}>
              <div>
                <Label
                  htmlFor="formFile"
                  className="form-label"
                  style={{
                    background: "#1d3f2e",
                    padding: "7px 13px",
                    color: "white",
                    borderRadius: "7px",
                    marginLeft: "2vw",
                  }}
                >
                  Import
                </Label>
                <Input
                  //for allowing sharing of same file name again
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onChange={handleFile}
                  className="form-control"
                  type="file"
                  id="formFile"
                  style={{ visibility: "hidden" }}
                />
              </div>
            </Col>
            {/* <Col xl={2} md={2}>
              <div>
                <Label
                  htmlFor="formFile1"
                  className="form-label"
                  style={{
                    background: "#1d3f2e",
                    padding: "7px 12px",
                    color: "white",
                    width: "12vw",
                    borderRadius: "7px",
                  }}
                >
                  Send Absentees
                </Label>
                <Input
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onChange={handleFile1}
                  className="form-control"
                  type="file"
                  id="formFile1"
                  style={{ visibility: "hidden" }}
                />
              </div>
            </Col> */}

            <Col lg={12} key={freshup}>
              {totalData == 1 ? (
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                    key={seed}
                    tableRef={tableRef}
                    title="Students List"
                    data={(query) =>
                      new Promise((resolve, reject) => {
                        // console.log("Start : " + query.page);
                        // console.log("length : " + query.pageSize);
                        // if (query.orderBy != undefined) {
                        axios
                          .get(
                            `https://api.imo-india.org/school/students/student-info?classId=${type}&schoolId=${localStorage.getItem(
                              "school-id"
                            )}&start=${query.page + 1}&length=${
                              query.pageSize
                            }`,
                            {
                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("token"),
                              },
                            }
                          )
                          .then((res) => {
                            if (res.status == 200) {
                              // console.log("Single page length: ");
                              // console.log(length);
                              // console.log(query.page);
                              resolve({
                                // data: tabData, // your data array
                                data: res.data.students, // your data array
                                page: query.page, // current page number
                                totalCount: res.data.recordsFiltered, // total row number
                              });
                              console.log(res);
                            }
                          })
                          .catch((err) => {
                            console.log(err);
                            // toast.error(err.response.data.error);
                            // resolve();
                            if (err.response.status == 404) {
                              // && query.page != 0) {
                              if (query.page != 0) {
                                setSeed(Math.random());
                              } else {
                                setTotalData(0);
                                // console.log("NOOOOO data");
                                // console.log(totalData);
                                setFreshup(Math.random());
                                // query.page = 1;
                                // query.totalCount = 1;
                                // console.log(query);
                                // reject();
                              }
                            }
                            resolve();
                            // window.location.reload(false);
                          });
                        // }
                      })
                    }
                    columns={tableColumns1}
                    actions={[
                      {
                        icon: "refresh",
                        tooltip: "Refresh Data",
                        isFreeAction: true,
                        onClick: () => setFreshup(Math.random()),
                        // onClick: () => tableRef.current.onQueryChange(),
                      },
                    ]}
                    editable={{
                      onRowAdd: (newRow) =>
                        new Promise((resolve, reject) => {
                          // setTableData([...tableData, newRow]);
                          onClickAdd(newRow);
                          resolve();
                        }),
                      onRowUpdate: (newRow, oldRow) =>
                        new Promise((resolve, reject) => {
                          // const updatedData = [...tableData];
                          // updatedData[oldRow.tableData.id] = newRow;
                          // setTableData(updatedData);
                          onClickUpdate(oldRow.id, newRow);
                          resolve();
                        }),
                      onRowDelete: (selectedRow) =>
                        new Promise((resolve, reject) => {
                          // const updatedData = [...tableData];
                          // updatedData.splice(selectedRow.tableData.id, 1);
                          // setTableData(updatedData);
                          onClickDelete(selectedRow);
                          resolve();
                        }),
                    }}
                    options={{
                      addRowPosition: "first",
                      search: false,
                      exportButton: false,
                      exportAllData: false,
                      exportFileName: "Documents",
                    }}
                  />
                </ThemeProvider>
              ) : (
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                    key={seed}
                    tableRef={tableRef}
                    title="Students List"
                    data={emptyData}
                    columns={tableColumns1}
                    actions={[
                      {
                        icon: "refresh",
                        tooltip: "Refresh Data",
                        isFreeAction: true,
                        onClick: () => setFreshup(Math.random()),
                        // onClick: () => tableRef.current.onQueryChange(),
                      },
                    ]}
                    editable={{
                      onRowAdd: (newRow) =>
                        new Promise((resolve, reject) => {
                          // setTableData([...tableData, newRow]);
                          onClickAdd(newRow);
                          resolve();
                        }),
                      onRowUpdate: (newRow, oldRow) =>
                        new Promise((resolve, reject) => {
                          // const updatedData = [...tableData];
                          // updatedData[oldRow.tableData.id] = newRow;
                          // setTableData(updatedData);
                          onClickUpdate(oldRow.id, newRow);
                          resolve();
                        }),
                      onRowDelete: (selectedRow) =>
                        new Promise((resolve, reject) => {
                          // const updatedData = [...tableData];
                          // updatedData.splice(selectedRow.tableData.id, 1);
                          // setTableData(updatedData);
                          onClickDelete(selectedRow);
                          resolve();
                        }),
                    }}
                    options={{
                      addRowPosition: "first",
                      search: false,
                      exportButton: false,
                      exportAllData: false,
                      exportFileName: "Documents",
                    }}
                  />
                </ThemeProvider>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ClassList;
