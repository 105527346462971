import React from "react";
import classes from "./Header.module.css";
import Select from "react-select";
import searchIcon from "../../assets/search-icon.svg";
import emailUnread from "../../assets/mark_email_unread.svg";
import profile_pic from "../../assets/profile_pic.svg";
import { useState } from "react";
import {
  Col,
  // Label,
  // Row,
  // UncontrolledDropdown,
} from "reactstrap";
const Header = () => {
  const [academicYear, setAcademicYear] = useState();
  const SingleOptions = [
    { value: "Academic Year 2022", label: "Academic Year 2022" },
    { value: "Academic Year 2023", label: "Academic Year 2023" },
    { value: "Academic Year 2024", label: "Academic Year 2024" },
  ];
  return (
    <div className={classes.mainHeader}>
      {/* <form>
        <input type="search" placeholder="What are you looking for ?" />
        <button type="submit">Search</button>
      </form> */}
      <div className={classes.subHeader1}>
        <h3>IMO</h3>
        <Col xxl={4} md={12}>
          <div className="acadSelect">
            {/* <Label
                      htmlFor="choices-single-default"
                      className="form-label"
                    >
                    </Label> */}
            <Select
              value={academicYear}
              onChange={(e) => {
                setAcademicYear(e);
              }}
              options={SingleOptions}
            />
          </div>
        </Col>
      </div>
      <div className={classes.subHeader2}>
        <label for="cars"></label>
        <select className="drop2Head" name="cars" id="cars">
          <option value="volvo"></option>
          <option value="volvo">Settings</option>
          <option value="saab">Log Out</option>
        </select>
        <img src={emailUnread} alt="" />
        <img src={profile_pic} alt="" />
      </div>
    </div>
  );
};

export default Header;
