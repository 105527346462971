import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import LearnHeader from "../../components/LearnHeader/LearnHeader";
// import Mock from "../../components/LearnHeader/Mock";

const LearnPage = () => {
  return (
    <>
      <Header />
      <LearnHeader />
      <Footer />
    </>
  );
};

export default LearnPage;
