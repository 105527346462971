import React, { useState, useRef } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import classes from "./ClassList.module.css";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Col, Row } from "reactstrap";

const Absentees = () => {
  const defaultMaterialTheme = createTheme();
  const tableRef = useRef();

  const [studentName, setStudentName] = useState("");
  const [enrollmentNo, setEnrollmentNo] = useState("");
  const [totalData, setTotalData] = useState(1);
  const [freshup, setFreshup] = useState(1);
  const tableColumns1 = [
    {
      title: "Name of Student",
      field: "studentName",
    },
    { title: "Enrollment Number", field: "enrollmentNo" },
  ];
  const [tableData, setTableData] = useState([
    {
      studentName: studentName,
      enrollmentNo: enrollmentNo,
    },
  ]);

  const onClickAdd = (obj) => {
    axios
      .post(
        `https://api.imo-india.org/school/student/enter-absentee?schoolId=${localStorage.getItem(
          "school-id"
        )}`,
        obj,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.status == 201) {
          tableRef.current.onQueryChange();
          console.log(res);
          setTotalData(1);
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data.message);
      });
  };
  const [seed, setSeed] = useState(1);
  const [emptyData] = [];
  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        <div className={classes.mainClassList}>
          <Row className="mt-4">
            <Col lg={12} key={freshup}>
              {totalData == 1 ? (
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                    key={seed}
                    tableRef={tableRef}
                    title="Absentee List"
                    data={(query) =>
                      new Promise((resolve, reject) => {
                        axios
                          .get(
                            `https://api.imo-india.org/school/student/absentee-list?schoolId=${localStorage.getItem(
                              "school-id"
                            )}&size=${query.pageSize}&start=${query.page + 1}`,
                            {
                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("token"),
                              },
                            }
                          )
                          .then((res) => {
                            if (res.status == 200) {
                              resolve({
                                data: res.data.absentList, // your data array
                                page: query.page, // current page number
                                totalCount: res.data.recordsFiltered, // total row number
                              });
                              console.log(res);
                            }
                          })
                          .catch((err) => {
                            console.log(err);
                            if (err.response.status == 404) {
                              if (query.page != 0) {
                                setSeed(Math.random());
                              } else {
                                setTotalData(0);
                                setFreshup(Math.random());
                              }
                            }
                            resolve();
                          });
                      })
                    }
                    columns={tableColumns1}
                    actions={[
                      {
                        icon: "refresh",
                        tooltip: "Refresh Data",
                        isFreeAction: true,
                        onClick: () => setFreshup(Math.random()),
                        // onClick: () => tableRef.current.onQueryChange(),
                      },
                    ]}
                    editable={{
                      onRowAdd: (newRow) =>
                        new Promise((resolve, reject) => {
                          onClickAdd(newRow);
                          resolve();
                        }),
                    }}
                    options={{
                      addRowPosition: "first",
                      actionsColumnIndex: -1,
                      search: false,
                      exportButton: false,
                      exportAllData: false,
                      exportFileName: "Documents",
                    }}
                  />
                </ThemeProvider>
              ) : (
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                    key={seed}
                    tableRef={tableRef}
                    title="Absentee List"
                    data={emptyData}
                    columns={tableColumns1}
                    actions={[
                      {
                        icon: "refresh",
                        tooltip: "Refresh Data",
                        isFreeAction: true,
                        onClick: () => setFreshup(Math.random()),
                        // onClick: () => tableRef.current.onQueryChange(),
                      },
                    ]}
                    editable={{
                      onRowAdd: (newRow) =>
                        new Promise((resolve, reject) => {
                          onClickAdd(newRow);
                          resolve();
                        }),
                    }}
                    options={{
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      search: false,
                      exportButton: false,
                      exportAllData: false,
                      exportFileName: "Documents",
                    }}
                  />
                </ThemeProvider>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Absentees;
