import React from "react";
import Header from "../../components/Header/Header";
import PDFView from "../../components/PDFView/PDFView";
import ResultForm from "../../components/ResultForm/ResultForm";

const ResultPage = () => {
  return (
    <div>
      <Header />
      <ResultForm />
      {/* <PDFView /> */}
    </div>
  );
};

export default ResultPage;
