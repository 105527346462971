import React from "react";
import { Link } from "react-router-dom";
import mof_logo from "../../assets/mof_logo.svg";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <span className="yellowLine"></span>
      <div className="footer-flex">
        <div className="footer-logo">
          <img style={{}} src={mof_logo} alt="" />
        </div>
        <div className="foot">
          <br />
          <div className="flexed">
            <i class="fa-sharp fa-solid fa-location-dot"></i>{" "}
            <span>
              Maths Olympiad Foundation
              <br />
              66 Pocket-10, Sector-24
              <br />
              Rohini, Delhi-110085
            </span>
          </div>
          <br />
          <div className="flexed">
            <i style={{ color: "FEB546" }} class="fa-solid fa-phone"></i>
            <span>9953-245-945</span>
          </div>
          <br />
          <div>
          <p className="sm">Social Media</p>
          <div className="social-media">
            <i class="fa-brands fa-facebook"></i>
            <i class="fa-brands fa-twitter"></i>
            <i class="fa-brands fa-linkedin"></i>
            <i class="fa-brands fa-youtube"></i>
          </div>
          </div>
        </div>
      </div>
      <span className="yellowLine"></span>
      <div className="links">
        <div className="subLinks">
          
          <Link to="/">ABOUT US</Link>
          <Link to="/">CONTACT US</Link>
          <Link to="/">HELP </Link>
          
          <div className="pp">
          <Link className="pp" to="/">PRIVACY POLICY</Link>
          <Link className="dis" to="/">DISCLAIMER</Link>
          </div>
        </div>
        <div className=" copy ">
          <p>Copyright ©RSINFY 2023 • Maths Olympiad Foundation.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
