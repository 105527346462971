import React, { useState, useMemo, useEffect } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import Home from "../../assets/home.svg";
import admitCard from "../../assets/admitCard.svg";
import myResults from "../../assets/myResults.svg";
import tutorials from "../../assets/tutorials.svg";
import certi from "../../assets/certi.svg";
import greetImg from "../../assets/greetImg.svg";
import stateTrophy from "../../assets/stateTrophy.svg";
import internationalTrophy from "../../assets/internationalTrophy.svg";
import prompt from "../../assets/singing-contract.svg";
import classes from "./HomePage.module.css";
import mof_logo from "../../assets/mof_logo.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  getMonthDetail,
  getDateDay,
  getTodayData,
  getPrevYearMonth,
  getNextYearMonth,
  week,
} from "./utils.js";
import MonthWrapper from "./MonthWrapper";
import { Navigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();
  const [today, setToday] = useState(getTodayData());
  const [year, setYear] = useState(today.year);
  const [month, setMonth] = useState(today.month);

  const todayStr = useMemo(() => {
    return Object.values(today).join("");
  }, [today]);

  const setYearMonth = (data) => {
    setYear(data.year);
    setMonth(data.month);
  };
  const goPrev = () => {
    setYearMonth(getPrevYearMonth({ year, month }));
  };
  const goNext = () => {
    setYearMonth(getNextYearMonth({ year, month }));
  };
  const goToday = () => {
    setYearMonth(today);
  };
   
  useEffect(() => {
    if(localStorage.getItem('enrollmentNo') === null) {
      console.log("null");
      navigate("/student/login")
    } else {
      var enrollmentNo = localStorage.getItem('enrollmentNo');
      console.log('enrollmentNo' + enrollmentNo);
    }
  })

  const submitlogout = (e) => {
    console.log("button click");
    e.preventDefault();

    if (e.target.value === 'saab'){
      localStorage.removeItem('enrollmentNo');
      navigate("/");
    }
  };
 
  const [toggle, setToggle] = useState(false);
  // console.log(toggle);
  const toggleClassHandler = (e) => {
    setToggle(!toggle);
  };
  useEffect(() => {}, [toggle]);
  
  return (
    <div>

      <div className="nav">
      <nav class="navbar navbar-dark bg-dark fixed-top d-block d-md-none d-lg-none d-xl-none">
  <div class="container-fluid">
    <a class="navbar-brand" ><Link to="/student/home"><img src={mof_logo} alt="" style={{ maxHeight: '60px', marginLeft:'15px' }} /></Link></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">Welcome</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page"><Link to="/student/home"><img className={classes.sideBarIcon} src={Home} alt="" /> Home  </Link></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#"><Link to="/student/admitcard"><img className={classes.sideBarIcon} src={myResults} alt="" />{" "}Admit Card</Link></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#"><Link to="/student/certificate" onClick={toggleClassHandler}><img className={classes.sideBarIcon} src={certi} alt="" />{" "}Certificates</Link></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#"><Link to="/student/tutorials"><img className={classes.sideBarIcon} src={tutorials} alt="" />{" "}Tutorials</Link></a>
          </li>
          <li>
          <div className=" middle ">
        <select onChange={submitlogout} name="cars" id="cars">
          <option value="volvo">More</option>
          <option value="volvo">Settings</option>
          <option  value="saab">Log Out</option>
        </select>
        </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
</div>

      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        <div className={classes.mainHome}>
          <div className={classes.infoHome}>
            <div className={classes.greetHome}>
              <div>
                <h2>Hello Saksham</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Officia non consequuntur quisquam placeat sed inventore,
                  libero suscipit
                </p>
              </div>
              <img src={greetImg} alt="" />
            </div>
            <div className={classes.mainRanking}>
              <h2>Your Rank</h2>
              <div className={classes.rankings}>
                <div className={classes.state}>
                  <img src={stateTrophy} alt="" />
                  <div>
                    <h3>170</h3>
                    <h5>State Level</h5>
                  </div>
                </div>
                <div className={classes.international}>
                  <img src={internationalTrophy} alt="" />
                  <div>
                    <h3>1,567</h3>
                    <h5>International Level</h5>
                  </div>
                </div>
                <div className={classes.school}>
                  <img src={stateTrophy} alt="" />
                  <div>
                    <h3>100</h3>
                    <h5>School Level</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.prompt}>
              <img src={prompt} alt="" />
              <p>Don’t forget to prepare for your exams!</p>
            </div>
          </div>
          <div className={classes.mainCal}>
            <MonthWrapper
              className={classes.calender}
              goPrev={goPrev}
              goNext={goNext}
              goToday={goToday}
              year={year}
              month={month}
              todayStr={todayStr}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
