import React, { useState, useMemo, useEffect } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import classes from "./Signup.module.css";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
// import MetaTags from "react-meta-tags";
import gif from "../../assets/signup-login.gif";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Container,
  Label,
  Row,
  textarea,
  UncontrolledDropdown,
} from "reactstrap";

const Signuo = () => {
  const c1 = ["class 1", "class 2", "class 3", "class 4", "class 5"];
  const SingleOptions = [
    { value: "Choices 1", label: "Choices 1" },
    { value: "Choices 2", label: "Choices 2" },
    { value: "Choices 3", label: "Choices 3" },
    { value: "Choices 4", label: "Choices 4" },
  ];

  const [IsSubmitting,setIsSubmitting] = useState("");
  const [ValidationErrors,setValidationErrors] = useState("");
  const [payload, setpayload] = useState("");

  const [optionsState, setOptionsState] = useState([]);
  const [optionsCity, setOptionsCity] = useState([]);
  const [isCityListData, setIsCityListData] = useState(false);
  const [stateChange, setStateChange] = useState(false);
  const [statesList, setStatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [schoolName, setSchoolName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [address, setAddress] = useState("");
  const [schoolState, setSchoolState] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [err, setErr] = useState(null);
  const [type, setType] = useState(0);
  const navigate = useNavigate();
  
  useEffect(() => {
    axios
      .get("https://api.imo-india.org/school/get-state")
      .then((res) => {
        console.log(res);
        setOptionsState([{ value: "", label: "" }]);
        setStatesList(res.data.states);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        `https://api.imo-india.org/school/get-city?state=${schoolState.value}`
      )
      .then((res) => {
        console.log(res);
        // setStateChange(true);
        setIsCityListData(true);
        setOptionsCity([{ value: "", label: "" }]);
        setCityList(res.data.cities);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [schoolState]);
  useEffect(() => {
    statesList.map((c) => {
      optionsState.push({ value: c, label: c });
    });
  }, [statesList]);
  useEffect(() => {
    if (isCityListData == true) {
      cityList.map((c) => {
        optionsCity.push({ value: c, label: c });
      });
      setCity("");
      setIsCityListData(false);
    }
  }, [cityList, schoolState]);
  const submitHandler = (event) => {
    event.preventDefault();
    const signupData = {
      name: schoolName,
      email: emailId,
      password: password,
      phoneNumber: phoneNumber,
      address: address,
      city: city.value,
      state: schoolState.value,
      pinCode: pinCode,
      profileImage: "",
    };
    axios
      .post(
        "https://api.imo-india.org/school/sign-up" ,
        signupData
        // , {
        //   headers: {
        //     "Access-Control-Allow-Origin": "*",
        //     "Access-Control-Allow-Credentials": true,
        //   },
        // }
      )
      .then((res) => {
        // console.log(res);
        // event.target.reset();
        if (res.status == 201) {
          // toast.success(res.data.message);
          setType(1);
          // toast.success("The account is created please wait for approval");
          // navigate("/school/log-in");
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
        // setErr(err.response.data.message);
      });


    //   useEffect(()=>{
    //     if(localStorage.getItem('token') != "" && localStorage.getItem('token') != null){
    //         navigate("/dashboard");
    //     }
    // },[])

      // e.preventDefault();
      //   setIsSubmitting(true)
      //   let payload = {
      //       name: name,
      //       email:email,
      //       password:password,
      //       password_confirmation:confirmPassword
      //   }
      // axios.post('/school/sign-up', payload)
      //   .then((r) => {
      //       setIsSubmitting(false)
      //       localStorage.setItem('token', r.data.token)
      //       navigate("/school/log-in");
      //   })
      //   .catch((e) => {
      //       setIsSubmitting(false)
      //       if (e.response.data.errors != undefined) {
      //           setValidationErrors(e.response.data.errors);
      //       }
      //   });

  };

  // const submitHandler = (event) =>{
  //   event.preventDefault();
  //   const signupData = {
  //     name: schoolName, email: emailId, password: password, phoneNumber: phoneNumber, address: address, city: city, state: schoolState, pinCode: pinCode, profileImage:""};

  //     fetch("https://api.imo-india.org/school/sign-up",{
  //       method:'POST',
  //       headers:{
  //         'Accept':'application/json',
  //         'Content-Type':'application/json'
  //       },
  //       body:JSON.stringify(signupData)
  //     }).then((result)=>{
  //       console.warn("result:",result);
  //       result.json().then((resp)=>{
  //         console.warn("resp:",resp)
  //       })
  //     })
  // };
  return (
    <React.Fragment>
      <div className={classes.page}>
        <div className={classes.pagehalf1}>
          <img src={gif} alt="" />
        </div>
        <div className={classes.pagehalf2}>
          {type == 0 ? (
            <div>
              <h2>
                Hi, <br /> Welcome
              </h2>
              <p>Kindly Sign Up to your account</p>
              <br />
              <Row>
                <Col xxl={6} md={12} className="mt-1">
                  <div>
                    <Label htmlFor="name">
                      <h2 className="zmdi zmdi-account material-icon-name" />
                      School Name
                    </Label>
                    <Input
                      value={schoolName}
                      onChange={(e) => {
                        setSchoolName(e.target.value);
                      }}
                      // defaultValue={1}
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      autoComplete="off"
                      // id="placeholderInput"
                      // placeholder=""
                    />
                  </div>
                </Col>
                <Col xxl={6} md={12} className="mt-3">
                  <div>
                    <Label htmlFor="email" className="form-label">
                      <h2 className="zmdi zmdi-email material-icon-name" />
                      Email ID
                    </Label>
                    <Input
                      value={emailId}
                      onChange={(e) => {
                        setEmailId(e.target.value);
                      }}
                      // defaultValue={1}
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      autoComplete="off"
                      // id="placeholderInput"
                      // placeholder=""
                    />
                  </div>
                </Col>
                <Col xxl={6} md={12} className="mt-3">
                  <div>
                    <Label htmlFor="phone" className="form-label">
                      <h2 className="zmdi zmdi-phone-in-talk material-icon-name" />
                      Phone Number
                    </Label>
                    <Input
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      // defaultValue={1}
                      type="number"
                      name="phone"
                      id="phone"
                      autoComplete="off"
                      className="form-control"
                      // id="placeholderInput"
                      // placeholder=""
                    />
                  </div>
                </Col>
                <Col xxl={6} md={12} className="mt-3">
                  <div>
                    <Label htmlFor="password">
                      <h2 className="zmdi zmdi-lock material-icon-name" />
                      Password
                    </Label>
                    <Input
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      // defaultValue={1}
                      type="password"
                      name="password"
                      id="password"
                      className="form-control"
                      autoComplete="off"
                      // id="placeholderInput"
                      // placeholder=""
                    />
                  </div>
                </Col>
                <Col xxl={6} md={12} className="mt-3">
                  <div>
                    <Label htmlFor="cpassword">
                      <h2 className="zmdi zmdi-lock material-icon-name" />
                      Confirm Password
                    </Label>
                    <Input
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      // defaultValue={1}
                      type="password"
                      name="cpassword"
                      id="cpassword"
                      className="form-control"
                      autoComplete="off"
                      // id="placeholderInput"
                      // placeholder=""
                    />
                  </div>
                </Col>
                {confirmPassword != password ? (
                  <Col xxl={6} md={12} id={classes.errorMessage}>
                    Please Confirm Password
                  </Col>
                ) : (
                  ""
                )}
                <Col xxl={6} md={12} className="mt-3">
                  <div>
                    <Label htmlFor="address">
                      <h2 className="zmdi zmdi-home material-icon-name" />
                      Address
                    </Label>
                    <textarea
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                      // defaultValue={1}
                      type="text"
                      name="address"
                      id="address"
                      className="form-control"
                      autoComplete="off"
                      rows="3"
                      // id="placeholderInput"
                      // placeholder=""
                    />
                  </div>
                </Col>
                <Col xxl={6} md={12} className="mt-3">
                  <div>
                    <Label htmlFor="schoolState" className="form-label">
                      <h2 className="zmdi zmdi-gps-dot material-icon-name" />
                      State
                    </Label>
                    <Select
                      value={schoolState}
                      onChange={(e) => {
                        setSchoolState(e);
                      }}
                      name="schoolState"
                      id="schoolState"
                      options={optionsState}
                    />
                  </div>
                </Col>
                <Col xxl={6} md={6} className="mt-3">
                  <div>
                    <Label htmlFor="city" className="form-label">
                      <h2 className="zmdi zmdi-gps-dot material-icon-name" />
                      City
                    </Label>
                    <Select
                      value={city}
                      onChange={(e) => {
                        setCity(e);
                      }}
                      name="city"
                      id="city"
                      options={optionsCity}
                    />
                  </div>
                </Col>
                <Col xxl={6} md={6} className="mt-3">
                  <div>
                    <Label htmlFor="pincode" className="form-label">
                      <h2 className="zmdi zmdi-pin material-icon-name" />
                      Pin Code
                    </Label>
                    <Input
                      value={pinCode}
                      onChange={(e) => {
                        setPinCode(e.target.value);
                      }}
                      // defaultValue={1}
                      type="number"
                      name="pincode"
                      id="pincode"
                      autoComplete="off"
                      className="form-control"
                      // id="placeholderInput"
                      // placeholder=""
                    />
                  </div>
                </Col>
                <Col xxl={12} md={12} className={classes.signupButton}>
                  <button
                    className="btn btn-success"
                    // type="submit"
                    // onClick={(e) => onFormCreateSubmit(e)}
                    onClick={submitHandler}
                  >
                    Sign-Up <h2 className="zmdi zmdi-mail-send" />
                  </button>
                </Col>
                <Col xxl={12} md={12}>
                  <p id={classes.errorMessage}>{err}</p>
                </Col>
                <Col xxl={12} md={12} className={classes.pageChangeBelowButton}>
                  {/* <p>Already Registered? <a href="/school/log-in">LogIn</a> </p> */}
                  <p>Already Registered? </p>{" "}
                  <Link to="/school/log-in">
                    <p id={classes.alreadyLogged}>LogIn</p>
                  </Link>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <div>
                <h1>
                  <VerifiedIcon
                    sx={{ marginTop: 10, marginBottom: 2, fontSize: 50 }}
                  />
                </h1>
              </div>
              <h2>The account is created, please wait for approval</h2>
              <br />
            </div>
          )}
        </div>
        
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </React.Fragment>
  );
};

export default Signuo;
