import React from "react";
import "./whyUs.css";
import whyUs1 from "../../assets/whyUs-1.svg";
import whyUs2 from "../../assets/whyUs-2.svg";
import whyUs3 from "../../assets/whyUs-3.svg";
import whyUs4 from "../../assets/whyUs-4.svg";
import { Button, Image } from "react-bootstrap";
// import landingAbout from "../../assets/landingAbout.svg";
import landingPar from "../../assets/landingPar.svg";
import arrow from "../../assets/Arrow.svg";
import { Link } from "react-router-dom";
const whyUs = () => {
  return (
    <div className="about-us">
      {/* <span
        style={{
          position: "relative",
          left: "120px",
          top: "30px",
          width: "6px",
          height: "26px",
          backgroundColor: "red",
          display: "block",
        }}
      ></span>{" "} */}
      {/* <h3>Why Us?</h3> */}
      <div
        style={{
          backgroundColor: "#1D3F2E",
          position: "relative",
          height: "29vh",
          top: "199px",
          zIndex: "-1",
        }}
      ></div>
      <div style={{ color: "#F9CB6D" }} className="whyus-main">
        <div>
          <img className="image-whyus" src={whyUs1} alt="" />
          <p>Improving problem-solving skills</p>
        </div>
        <div>
          <img className="image-whyus" src={whyUs2} alt="" />
          <p>Building confidence</p>
        </div>
        <div>
          <img className="image-whyus" src={whyUs3} alt="" />
          <p>Exposure to advanced math concepts</p>
        </div>
        <div>
          <img className="image-whyus" src={whyUs4} alt="" />
          <p>Opportunities for recognition</p>
        </div>
      </div>
      <div className="par-landing">
        <div className=" image-par ">
          <Image className="image-par" src={landingPar}></Image>
        </div>
        <div>
          <h2 className="font-size" style={{ fontWeight: "bold" }}>How to Participate?</h2>
          <p>
            The Maths Olympiad Foundation (MOF) offers the opportunity for
            students to participate in IMO through their schools.
            <br /> <br />
            The Olympiad Coordinator can download the Brochure after the School
            Registration for more information.
            <br /> <br />
            Participating in the IMO can be a rewarding experience, as it allows
            you to compete with talented students from around the world and
            showcase your mathematical skills on an international stage. So,
            don&#39;t forget to inform your school about your interest in the
            IMO.
          </p>
          <a href="/participate">
            <Button style={{ backgroundColor: "#1D3F2E" }} variant="success">
              Know more <Image src={arrow}></Image>{" "}
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default whyUs;
