import React, { useState, useMemo, useEffect } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import { Carousel } from "react-bootstrap";
import "./certificate.css";
import Home from "../../assets/home.svg";
import admitCard from "../../assets/admitCard.svg";
import myResults from "../../assets/myResults.svg";
import tutorials from "../../assets/tutorials.svg";
import certi from "../../assets/certi.svg";
import greetImg from "../../assets/greetImg.svg";
import stateTrophy from "../../assets/stateTrophy.svg";
import internationalTrophy from "../../assets/internationalTrophy.svg";
import prompt from "../../assets/singing-contract.svg";
import classes from "./HomePage.module.css";
import mof_logo from "../../assets/mof_logo.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import greenBg from "../../assets/greenBG.svg";

const Certificate = () => {

  const navigate = useNavigate();
  const [enrollmentNo, setEnrollmentNo] = useState("");
  const [data, setData] = useState("");

  useEffect(() => {

    if(localStorage.getItem('enrollmentNo') === null) {
      console.log("null");
      navigate("/student/login")
    } else {
      var enrollmentNo = localStorage.getItem('enrollmentNo');
      console.log('enrollmentNo ' + enrollmentNo); 
    }

  },[])

  const [toggle, setToggle] = useState(false);
  // console.log(toggle);
  const toggleClassHandler = (e) => {
    setToggle(!toggle);
  };
  // useEffect(() => {}, [toggle]);

  const submitlogout = (e) => {
    console.log("button click");
    e.preventDefault();

    if (e.target.value === 'saab'){
      localStorage.removeItem('enrollmentNo');
      navigate("/");
    }
  };


  return (
    <div>

     <div className="nav">
      <nav class="navbar navbar-dark bg-dark fixed-top d-block d-md-none d-lg-none d-xl-none">
      <div class="container-fluid">
      <a class="navbar-brand"><Link to="/student/home"><img src={mof_logo} alt="" style={{ maxHeight: '60px', }} /></Link></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">Welcome</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page"><Link to="/student/home"><img className={classes.sideBarIcon} src={Home} alt="" /> Home  </Link></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#"><Link to="/student/admitcard"><img className={classes.sideBarIcon} src={myResults} alt="" />{" "}Admit Card</Link></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#"><Link to="/student/certificate" onClick={toggleClassHandler}><img className={classes.sideBarIcon} src={certi} alt="" />{" "}Certificates</Link></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#"><Link to="/student/tutorials"><img className={classes.sideBarIcon} src={tutorials} alt="" />{" "}Tutorials</Link></a>
          </li>
          <li>
          <div className=" middle ">
        <select onChange={submitlogout} name="cars" id="cars">
          <option value="volvo">More</option>
          <option value="volvo">Settings</option>
          <option  value="saab">Log Out</option>
        </select>
        </div>
          </li>
           </ul>
           </div>
          </div>
         </div>
      </nav>
    </div>

      <div>
        <SideBar style={{ background: "white" }} />
      </div>
      <div className="bg-support">
        <Header />

        <Carousel className="carousel">
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://marketplace.canva.com/EAFIEvneNCM/1/0/1600w/canva-golden-elegant-certificate-of-appreciation-0bN-aLORS9U.jpg"
              alt="First slide"
            />
          </Carousel.Item>
          {/* <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://marketplace.canva.com/EAFIEvneNCM/1/0/1600w/canva-golden-elegant-certificate-of-appreciation-0bN-aLORS9U.jpg"
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://marketplace.canva.com/EAFIEvneNCM/1/0/1600w/canva-golden-elegant-certificate-of-appreciation-0bN-aLORS9U.jpg"
              alt="Third slide"
            />
          </Carousel.Item> */}
        </Carousel>
      </div>
    </div>
  );
};

export default Certificate;