import React, { useState, useMemo } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import classes from "./SchoolData.module.css";
// import { Link,  useParams, } from "react-router-dom";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
import { Link } from "@material-ui/core";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

const Tutorials = () => {
  const defaultMaterialTheme = createTheme();

  const tableColumns1 = [
    {
      title: "ID",
      field: "id",
    },
    {
      title: "Name",
      field: "name",
    },
    { title: "Date", field: "date" },
    {
      title: "View", 
      render:rowData=><Link href={`/admin/tutorials/${rowData.id}`} target="_blank">View</Link>
    },
  ];
  const [tableData, setTableData] = useState([
    {
      id: "1",
      name: "Mock Test Paper 1(Class 1)",
      date: "02-12-2022"
    },
    {
      id: "2",
      name: "Mock Test Paper 2(Class 1)",
      date: "02-12-2022"
    },
  ]);

  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        <div className={classes.mainClassList}>
          {/* <h2>List</h2> */}
          <Row className="mt-4">
                <Col lg={12}>
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                    title="Sample Papers"
                    data={tableData}
                    columns={tableColumns1}
                    actions={[
                      {
                        icon: "refresh",
                        tooltip: "Refresh Data",
                        isFreeAction: true,
                      },
                    ]}
                    
                    editable={{
                      onRowAdd: (newRow) =>
                        new Promise((resolve, reject) => {
                          setTableData([...tableData, newRow]);
                          resolve();
                        }),
                      onRowUpdate: (newRow, oldRow) =>
                        new Promise((resolve, reject) => {
                          const updatedData = [...tableData];
                          updatedData[oldRow.tableData.id] = newRow;
                          setTableData(updatedData);
                          resolve();
                        }),
                      onRowDelete: (selectedRow) =>
                        new Promise((resolve, reject) => {
                          const updatedData = [...tableData];
                          updatedData.splice(selectedRow.tableData.id, 1);
                          setTableData(updatedData);
                          resolve();
                        }),
                    }}
                    options={{
                      exportButton: false,
                      exportAllData: false,
                      exportFileName: "Documents",
                      sorting: true,
                    }}
                  />
                  </ThemeProvider>
                </Col>
              </Row>
        </div>
      </div>
    </div>
  );
};

export default Tutorials;
