import React, { useState, useMemo } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import classes from "./SchoolData.module.css";
// import { Link,  useParams, } from "react-router-dom";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
import { Link } from "@material-ui/core";
import "./changer.css";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import SortBar from "./SortBar.js";

const SchoolData = () => {
  const defaultMaterialTheme = createTheme();

  const tableColumns1 = [
    {
      title: "Name of School",
      field: "schoolName",
    },
    { title: "Address", field: "address" },
    {
      title: "Mail",
      field: "mail",
    },
    {
      title: "Phone Number",
      field: "mobileNum",
    },
    {
      title: "Receipt", 
      render:rowData=><Link href={`/admin/school-data/${rowData.schoolName}`} target="_blank">View</Link>
    },
  ];
  const [tableData, setTableData] = useState([
    {
      schoolName: "Ravindra Public School",
      address: "QD block (near tv tower) Pitampura",
      mail: "xyz@gmail.com",
      mobileNum: "8927146723",
    },
    {
      schoolName: "Queen Mary's School",
      address: "Railway Colony, Tiz Hazari",
      mail: "abc@gmail.com",
      mobileNum: "9947146723",
    },
  ]);
  const [click, setClick] = useState(false);
  const [sort, setSort] = useState(null);
  const sortList = [
    {
      id: 1,
      text: "Total Schools",  
      catt: "total-schools",
    },
    {
      id: 2,
      text: "Receipt Uploaded", 
      catt: "receipt-uploaded", 
    },
    { id: 3,
      text: "Receipt Not Uploaded", 
      catt: "receipt-not-uploaded", 
    }
    ];
  const handleClick = () => setClick(!click);

  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        <div className={classes.mainClassList}>
          <div className={classes.filtersClassList}>
            {sortList.map(item=>(
              <div onClick={() => setSort(item.catt)} key={item.id} className={sort === item.catt ? "sortElementActive" : "sortElement"} >
                <h5>{item.text}</h5>
              </div>
            ))}
          </div>
          <Row >
                <Col lg={12}>
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                  // style={{ background: '#76B29366' }}
                    title="Finance Data"
                    data={tableData}
                    columns={tableColumns1}
                    actions={[
                      {
                        icon: "refresh",
                        tooltip: "Refresh Data",
                        isFreeAction: true,
                      },
                    ]}
                    options={{
                      exportButton: false,
                      exportAllData: false,
                      exportFileName: "Documents",
                      sorting: true,
                      
                      // rowStyle: {
                        // backgroundColor: '#FFF',
                        // color: '#FFF',
                      // }
                    }}
                  />
                  </ThemeProvider>
                </Col>
              </Row>
        </div>
      </div>
    </div>
  );
};

export default SchoolData;
