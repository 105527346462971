import React, { useState, useMemo } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import classes from "./PaymentSlip.module.css";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Container,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

const UploadList = () => {
  const c1 = ["class 1", "class 2", "class 3", "class 4", "class 5"];
  const c2 = ["class 6", "class 7", "class 8"];
  const c3 = ["class 9", "class 10", "class 11", "class 12"];
  const SingleOptions = [
    { value: "Choices 1", label: "Choices 1" },
    { value: "Choices 2", label: "Choices 2" },
    { value: "Choices 3", label: "Choices 3" },
    { value: "Choices 4", label: "Choices 4" },
  ];
  const [examID, setExamID] = useState("");
  const [participantNum, setParticipantNum] = useState("");
  const [draftNum, setDraftNum] = useState("");
  const [date, setDate] = useState("");
  const [issuingBank, setIssuingBank] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [paymentReceipt, setPaymentReceipt] = useState("");
  const [state, setState] = useState({
    file: null,
  });
  function handleFile(e) {
    e.preventDefault();
    let file = e.target.files[0];
    setState({ file: file });
    console.log(e.target.files);
    setPaymentReceipt(e.target.files[0]);
    // const receipt = new FormData();
    // receipt.append("file", paymentReceipt);

    // axios.post("https://api.imo-india.org/school/payment/upload", receipt)
    //     .then((response) => {
    //       console.log(response);
    //       e.target.reset();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
  }
  const submitHandler = (event) => {
    event.preventDefault();
    let file = state.file;
    console.log(state, "state data:");
    console.log(file, "state file:");
    const receipt = new FormData();
    receipt.append("receipt", file);
    // receipt.append("file", paymentReceipt);
    // const paymentData = {
    //   numOfParticipants: participantNum, draftNo: draftNum, issuingBank: issuingBank, dateOfPayment: date, totalAmount: totalAmount, paymentReceiptUrl: paymentReceipt};
    axios
      .put("https://api.imo-india.org/school/payment/upload", receipt, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.status == 200) {
          const paymentData = {
            examId: examID,
            numOfParticipants: participantNum,
            draftNo: draftNum,
            issuingBank: issuingBank,
            dateOfPayment: date,
            totalAmount: totalAmount,
            paymentReceiptUrl: res.data["publicURL"],
          };
          axios
            .post(
              "https://api.imo-india.org/school/payment/create",
              paymentData,
              {
                headers: {
                  // 'Content-Type': 'application/json',
                  // 'Content-Type': 'multipart/form-data',
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              console.log(response);
              toast.success(response.data.message);
              // event.target.reset();
            })
            .catch((err) => {
              console.log("upload error");
              console.log(err);
              toast.error(err.response.data.message);
              // console.log(issuingBank);
              // console.log(issuingBank.value);
            });
        }
        // console.log(res);
        // event.target.reset();
      })
      .catch((error) => {
        console.log("receipt error");
        toast.error(error.response.data.message);
        console.log(error);
      });
    // axios.post("https://api.imo-india.org/school/payment/create", paymentData)
    //   .then((response) => {
    //     console.log(response);
    //     event.target.reset();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
  return (
    <React.Fragment>
      <div>
        <div>
          <SideBar />
        </div>
        <div>
          <Header />
        </div>
        <Container fluid>
          <Card className={classes.mainPaymentSlip}>
            <CardHeader>
              <h5>Payment Slip</h5>
            </CardHeader>
            <CardBody>
              <div className={classes.mainUploadList}>
                {/* <form onSubmit={submitHandler}> */}
                {/* <form onSubmit={(e) => submitHandler(e)}> */}
                <Row>
                  <Col xxl={3} md={6}>
                    <div>
                      <Label htmlFor="basiInput" className="form-label">
                        Exam ID
                      </Label>
                      <Input
                        value={examID}
                        onChange={(e) => {
                          setExamID(e.target.value);
                        }}
                        // defaultValue={1}
                        type="number"
                        className="form-control"
                        // id="placeholderInput"
                        // placeholder=""
                      />
                    </div>
                  </Col>
                  <Col xxl={3} md={6}>
                    <div>
                      <Label htmlFor="basiInput" className="form-label">
                        Number of Participants
                      </Label>
                      <Input
                        value={participantNum}
                        onChange={(e) => {
                          setParticipantNum(e.target.value);
                        }}
                        // defaultValue={1}
                        type="number"
                        className="form-control"
                        // id="placeholderInput"
                        // placeholder=""
                      />
                    </div>
                  </Col>
                  <Col xxl={3} md={6}>
                    <div>
                      <Label htmlFor="basiInput" className="form-label">
                        Draft Number
                      </Label>
                      <Input
                        value={draftNum}
                        onChange={(e) => {
                          setDraftNum(e.target.value);
                        }}
                        // defaultValue={1}
                        type="text"
                        className="form-control"
                        // id="placeholderInput"
                        // placeholder=""
                      />
                    </div>
                  </Col>
                  <Col xxl={3} md={6}>
                    <div>
                      <Label className="form-label mb-0">Date</Label>
                      {/* <Label>Date</Label> */}
                      <Flatpickr
                        value={date}
                        className="form-control"
                        onChange={(e) => {
                          setDate(e);
                        }}
                        options={{
                          // altInput: true,
                          allowInput: "true",
                          // dateFormat: "d, M, Y",
                          dateFormat: "d M, Y",
                        }}
                      />
                    </div>
                  </Col>
                  <Col xxl={3} md={6}>
                    <div className="mb-3">
                      <Label
                        htmlFor="choices-single-default"
                        className="form-label"
                      >
                        Issuing Bank
                      </Label>
                      <Input
                        value={issuingBank}
                        onChange={(e) => {
                          setIssuingBank(e.target.value);
                        }}
                        // defaultValue={1}
                        type="text"
                        className="form-control"
                        // id="placeholderInput"
                        // placeholder=""
                      />
                    </div>
                  </Col>
                  <Col xxl={3} md={6}>
                    <div>
                      <Label htmlFor="basiInput" className="form-label">
                        Total Amount
                      </Label>
                      <Input
                        value={totalAmount}
                        onChange={(e) => {
                          setTotalAmount(e.target.value);
                        }}
                        // defaultValue={1}
                        type="number"
                        className="form-control"
                        // id="placeholderInput"
                        // placeholder=""
                      />
                    </div>
                  </Col>
                  <Col xxl={3} md={6}>
                    <div>
                      <Label htmlFor="formFile" className="form-label">
                        Payment Receipt
                      </Label>
                      <Input
                        className="form-control"
                        type="file"
                        onChange={handleFile}
                        id="formFile"
                      />
                    </div>
                  </Col>
                  <Col xxl={3} md={6}></Col>
                  <Col xxl={3} md={6}>
                    <button
                      className="btn btn-success"
                      // type="submit"
                      style={{
                        background: "#1d3f2e",
                        padding: "7px 13px",
                        color: "white",
                        borderRadius: "7px",
                        margin: "3vw 0vw",
                      }}
                      // onClick={(e) => onFormCreateSubmit(e)}
                      onClick={submitHandler}
                    >
                      Submit
                    </button>
                  </Col>
                </Row>
                {/* </form> */}
              </div>
            </CardBody>
          </Card>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UploadList;
