import "./App.css";
import React from "react";
import LandingPage from "./Pages/LandingPage/LandingPage";
import ToppersPage from "./Pages/ToppersPage/ToppersPage";
import AboutPage from "./Pages/AboutPage/AboutPage.jsx";
import ResultPage from "./Pages/ResultPage/ResultPage";
import ContactUsPage from "./Pages/ContactUsPage/ContactUsPage";
import LearnPage from "./Pages/LearnPage/LearnPage";
import Participate from "./Pages/ParticipatePage/Participate.jsx";
import Fame from "./Pages/FamePage/Fame.jsx";

import {
  HomePage,
  AdmitCard,
  Certificate,
  ResultsPage,
  Settings,
  Tutorials,
  StudentLogin,
} from "./Portals/studentPortal/exportStudentFiles";

import SchoolProtected from "./Portals/schoolPortal/Protected.jsx";
import {
  SchoolHome,
  UploadList,
  Result,
  PaymentSlip,
  Sheets,
  ClassList,
  Details,
  Signup,
  Login,
  StudentData,
  Absentees,
} from "./Portals/schoolPortal/exportSchoolFiles";
import {
  SchoolData,
  SingleSchoolData,
  FinanceData,
  AnswerTransport,
  QuestionTransport,
  AAdmitCard,
  ATutorials,
} from "./Portals/adminPortal/exportAdminFiles";
// import SchoolHome from "./Portals/schoolPortal/SchoolHome";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/Login" element={<Portals/>} /> */}
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="/learn" element={<LearnPage />} />
        <Route path="/results" element={<ResultPage />} />
        <Route path="/toppers" element={<ToppersPage />} />
        <Route path="/participate" element={<Participate />} />
        <Route path="/fame" element={<Fame />} />
        <Route path="/student/home" element={<HomePage />} />
        <Route path="/student/result" element={<ResultsPage />} />
        <Route path="/student/admitcard" element={<AdmitCard />} />
        <Route path="/student/tutorials" element={<Tutorials />} />
        <Route path="/student/login" element={<StudentLogin />} />
        <Route path="/student/certificate" element={<Certificate />} />
        <Route path="/student/settings" element={<Settings />} />
        {/* SCHOOL */}
        <Route
          path="/school/home"
          element={<SchoolProtected Component={SchoolHome} />}
        />
        <Route
          path="/school/student-data"
          element={<SchoolProtected Component={StudentData} />}
        />
        <Route
          path="/school/result"
          element={<SchoolProtected Component={Result} />}
        />
        <Route
          path="/school/upload-list"
          element={<SchoolProtected Component={UploadList} />}
        />
        {/* <Route path="/school/upload-list/trr" element={<ClassList/>} /> */}
        <Route
          path="/school/upload-list/:type"
          element={<SchoolProtected Component={ClassList} />}
        />
        <Route
          path="/school/details"
          element={<SchoolProtected Component={Details} />}
        />
        <Route
          path="/school/sheets"
          element={<SchoolProtected Component={Sheets} />}
        />
        <Route
          path="/school/payment-slip"
          element={<SchoolProtected Component={PaymentSlip} />}
        />
        <Route
          path="/school/settings"
          element={<SchoolProtected Component={SchoolHome} />}
        />
        <Route
          path="/school/absentee"
          element={<SchoolProtected Component={Absentees} />}
        />
        <Route path="/school/sign-up" element={<Signup />} />
        <Route path="/school/log-in" element={<Login />} />

        {/* ADMIN */}
        <Route path="/admin/school-data" element={<SchoolData />} />
        <Route path="/admin/school-data/:type" element={<SingleSchoolData />} />
        <Route path="/admin/finance-data" element={<FinanceData />} />
        <Route
          path="/admin/transport/question-sent"
          element={<QuestionTransport />}
        />
        <Route
          path="/admin/transport/answer-received"
          element={<AnswerTransport />}
        />
        <Route path="/admin/tutorials" element={<ATutorials />} />
        <Route path="/admin/master-admit" element={<AAdmitCard />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
