import React, { useState, useMemo } from "react";
import SideBar from "./SideBar.js";
import Header from "./Header.jsx";
import classes from "./UploadList.module.css";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

const UploadList = () => {
  const c1 = ["class 1", "class 2", "class 3", "class 4", "class 5"];
  const c2 = ["class 6", "class 7", "class 8"];
  const c3 = ["class 9", "class 10", "class 11", "class 12"];
  const [is1Disabled, setIs1Disabled] = useState(true);

  // const [style1, setStyle1] = useState("st1");
  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        <div className={classes.mainUploadList}>
          <div
            className={classes.standard}
            id={classes.standard1}
            // onClick={handleSubmit1}
          >
            <div className={classes.standardName}>Lower Primary</div>
            <div className={classes.standards}>
              {/* <div className={style1} id={style1}> */}
              {c1.map((item, index) => (
                <div>
                  <Link
                    to={{
                      // pathname: `/school/upload-list/trr`,
                      pathname: `/school/upload-list/${index + 1}`,
                      state: {
                        stateParam: true,
                      },
                    }}
                    className="text-primary d-inline-block edit-item-btn"
                  >
                    {/* <Button disabled={is1Disabled} key={index}>{item}</Button> */}
                    <Button key={index}>{item}</Button>
                  </Link>
                </div>
              ))}
              {/* <div>class 2</div> */}
            </div>
          </div>
          <div
            className={classes.standard}
            id={classes.standard2}
            // onClick={handleSubmit2}
          >
            <div className={classes.standardName}>Upper Primary</div>
            <div className={classes.standards}>
              {c2.map((item, index) => (
                <div>
                  <Link
                    to={{
                      pathname: `/school/upload-list/${index + 6}`,
                      state: {
                        stateParam: true,
                      },
                    }}
                    className="text-primary d-inline-block edit-item-btn"
                  >
                    {/* <Button disabled={is2Disabled} key={index}> */}
                    <Button key={index}>{item}</Button>
                  </Link>
                </div>
              ))}
              {/* <div>class 2</div> */}
            </div>
          </div>
          <div
            className={classes.standard}
            id={classes.standard3}
            // onClick={handleSubmit3}
          >
            <div className={classes.standardName}>Senior Secondary</div>
            <div className={classes.standards}>
              {c3.map((item, index) => (
                <div>
                  <Link
                    to={{
                      pathname: `/school/upload-list/${index + 9}`,
                      state: {
                        stateParam: true,
                      },
                    }}
                    className="text-primary d-inline-block edit-item-btn"
                  >
                    {/* <Button disabled={is3Disabled} key={index}> */}
                    <Button key={index}>{item}</Button>
                  </Link>
                </div>
              ))}
              {/* <div>class 2</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadList;
