import React from "react";
import classes from "./Header.module.css";

// import searchIcon from "../../assets/search-icon.svg";
import emailUnread from "../../assets/mark_email_unread.svg";
import { useNavigate } from "react-router-dom";
// import profile_pic from "../../assets/profile_pic.svg";

const profile_pic = localStorage.getItem("profile-image");
const Header = () => {
  const navigate = useNavigate();
  const operation = (e) => {
    if (e == "logOut") {
      console.log("logout operation started");
      localStorage.removeItem("token");
      navigate("/school/log-in");
    }
    console.log("operation started" + e);
  };
  return (
    <div className={classes.mainHeader}>
      {/* <form>
        <input type="search" placeholder="What are you looking for ?" />
        <button type="submit">Search</button>
      </form> */}
      <div className={classes.subHeader}>
        <label for="cars"></label>
        <select
          className="drop2Head"
          name="crs"
          id="crs"
          onChange={(val) => operation(val.target.value)}
        >
          {/* <option value="volvo"></option> */}
          <option value="setting">Settings</option>
          <option value="logOut">Log Out</option>
          {/* <option>
            <div onClick={operation}>Log Out</div>
          </option> */}
        </select>
        <img src={emailUnread} alt="" />
        <img className={classes.dpIcon} src={profile_pic} alt="" />
      </div>
    </div>
  );
};

export default Header;
