import React from "react";
import question from "../../assets/question.svg";
import { Link } from "react-router-dom";
import "./Question.css";

const Questions = () => {
  return (
    <div className="qd" style={{ padding: "10px 140px" }}>
      <div className="question">
        <div className="ques-details">
          <p>
            Ask your concern
          </p>
          <div className="call-det">
            <Link to="/contact">
              <button>Contact Us </button>
            </Link>
            <span className="call" >or call<br></br> +91 9953245945</span>
          </div>
        </div>
        <img src={question} alt="" />
      </div>
    </div>
  );
};

export default Questions;
